<template>
  <div id="page-content" class="d-flex flex-column">
    <v-row id="page-content-row" class="d-flex flex-grow-1 flex-shrink-0">
      <v-col
        v-if="!hideMainContent"
        id="page-main-content"
        cols="1"
        style="min-width: 100px; max-width: 100%"
        class="d-flex flex-column flex-grow-1 flex-shrink-0"
      >
        <div
          v-if="!hideHeader"
          id="main-header"
          class="flex-grow-0 flex-shrink-0"
          :style="'height:' + headerHeight"
        >
          <slot name="header"></slot>
        </div>
        <div class="flex-grow-1 page-content">
          <slot name="content"></slot>
        </div>
        <div
          v-if="!hideActions"
          id="main-actions"
          class="flex-grow-0 flex-shrink-0 page-actions"
        >
          <slot name="actions"></slot>
        </div>
      </v-col>
      <v-col
        v-if="showSideContent"
        id="page-side-content"
        :cols="sizeSideContent"
        style="min-height:100%"
        class="flex-grow-0 flex-shrink-0 page-content"
      >
        <slot name="content-side"></slot>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'PageContent',
  props: {
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    hideMainContent: {
      type: Boolean,
      default: false
    },
    showSideContent: {
      type: Boolean,
      default: false
    },
    sizeSideContent: {
      type: Number,
      default: 4
    },
    headerHeight: {
      type: String,
      default: '50px'
    }
  }
};
</script>
<style lang="scss" scoped>
.page-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 300px;
}
.page-actions {
  height: 50px;
}
</style>
