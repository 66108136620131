import Vue from 'vue';

const state = Vue.observable({
  user: null,
  tenant: null,
  isDevOps: false,
  title: 'res.share',
  showDrawer: null
});

export const store = {
  getUser: () => state.user,
  getTenant: () => {
    if (state.tenant) return state.tenant;

    var user = store.getUser();
    if (user && user.username) {
      state.tenant = Vue.$cookies.get(user.username + '_tenant');
      return state.tenant;
    }
    return null;
  },
  getIsDevOps: () => {
    return state && state.tenant && state.tenant.id === 'devops';
  },
  getTitle: () => state.title,
  getDrawerState: () => state.showDrawer
};

export const mutations = {
  setUser: val => {
    state.user = val;
  },
  setTitle: val => {
    state.title = val;
  },
  setTenant: val => {
    var user = store.getUser();

    if (val) {
      state.tenant = val;

      if (user.username) Vue.$cookies.set(user.username + '_tenant', val);
    } else {
      state.tenant = null;
      if (user && user.username) Vue.$cookies.remove(user.username + '_tenant');
    }
  },
  setDrawerState: val => {
    state.showDrawer = val;
  }
};
