<template>
  <v-list flat>
    <v-list-item v-for="(doc, i) in documents" :key="i">
      {{ i + 1 }}. {{ doc.document.standardProperties.title }}
      <v-spacer></v-spacer>

      <span v-if="doc.status === 'loading'">
        {{ $t('message.info.loading') }}
        <v-icon color="secondary">
          mdi-loading mdi-spin
        </v-icon>
      </span>

      <span v-if="doc.status === 'success'">
        {{ $t('message.success.save') }}
        <v-icon color="green">
          mdi-check-circle
        </v-icon>
      </span>

      <span v-if="doc.status === 'error'">
        {{ $t('message.error.save') }}
        <v-icon color="red">
          mdi-close-circle
        </v-icon>
      </span>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'DocumentProgress',
  props: {
    documents: {
      type: Array,
      required: true
    }
  }
};
</script>
