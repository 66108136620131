<template>
  <Centered>
    <v-card>
      <v-card-title>Willkommen</v-card-title>
      <v-card-text>
        <p>
          Herzlich willkommen zur Austausch-Plattform res.share der rocon GmbH.
        </p>
        <p>
          Hier haben Sie die Möglichkeit Dokumente ganz bequem mit Ihren
          Geschäftspartnern auszutauschen.
        </p>
      </v-card-text>
    </v-card>
  </Centered>
</template>
<script>
import Centered from '@/components/layout/Centered';

export default {
  name: 'Welcome',
  components: {
    Centered
  }
};
</script>
