<template>
  <Centered>
    <h2>
      {{ $t('header.resetPassword') }}
    </h2>
    <div v-if="!resetMailSent" id="emailform">
      <v-text-field
        v-model="email"
        :label="$t('model.email')"
        append-icon="mdi-send"
        @click:append="sendResetMail"
      ></v-text-field>
    </div>

    <div v-else id="codeform">
      <v-text-field
        v-model="resetCode"
        :label="$t('model.resetCode')"
        autocomplete="reset-code"
      ></v-text-field>
      <PasswordField
        v-model="newPassword"
        autocomplete="new-password"
        :label="$t('model.newPassword')"
      ></PasswordField>

      <v-btn class="float-right" :disabled="!canReset" @click="confirmCode">
        <v-icon>
          mdi-content-save
        </v-icon>
        {{ this.$t('button.save') }}
      </v-btn>
    </div>
  </Centered>
</template>

<script>
import AuthService from '@/service/auth';
import Centered from '@/components/layout/Centered';
import Notification from '@/service/notification';
import PasswordField from '@/components/inputs/PasswordField';

export default {
  name: 'ResetPassword',
  components: {
    Centered,
    PasswordField
  },
  data() {
    return {
      email: '',
      resetCode: '',
      newPassword: '',
      resetMailSent: false
    };
  },
  computed: {
    canSendMail: function() {
      return this.email && this.email.trim();
    },
    canReset: function() {
      return this.resetCode && this.resetCode.trim() && this.newPassword;
    }
  },
  methods: {
    sendResetMail() {
      if (!this.canSendMail) {
        return;
      }

      Notification.inProgress(true);
      AuthService.resetPassword(this.email.trim())
        .then(r => {
          console.info('reset mail successfully sent: ', r);
          this.resetMailSent = true;
          Notification.success(this.$t('message.success.sendMail'));
        })
        .catch(err => {
          console.error('sending reset mail failed: ', err);
          Notification.error(this.$t('message.error.sendMail'));
        })
        .finally(() => Notification.inProgress(false));
    },
    confirmCode() {
      if (!this.canReset) {
        return;
      }

      Notification.inProgress(true);
      AuthService.resetPasswordSubmit(
        this.email.trim(),
        this.resetCode.trim(),
        this.newPassword
      )
        .then(r => {
          console.info('password successfully resetted: ', r);
          Notification.success(this.$t('message.success.changePassword'));
          this.$router.push('/login');
          return;
        })
        .catch(err => {
          console.error('error resetting password: ', err);
          Notification.error(this.$t('message.error.changePassword'));
        })
        .finally(() => Notification.inProgress(false));
    }
  }
};
</script>
