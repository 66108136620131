<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('header.attributeDetails') }}
      </h2>
      <v-divider></v-divider>
    </template>
    <template v-slot:content>
      <v-form class="px-2">
        <v-text-field
          v-model="attribute.name"
          :label="$t('model.name') + '*'"
        ></v-text-field>
        <v-text-field
          v-model="attribute.nameEnglish"
          :label="$t('model.nameEnglish')"
        ></v-text-field>
      </v-form>
    </template>
    <template v-slot:actions>
      <div class="float-right">
        <ResponsiveButton
          icon="mdi-close-circle"
          icon-left
          :text="$t('button.cancel')"
          @click="navigateToList"
        >
        </ResponsiveButton>
        <ResponsiveButton
          color="primary"
          class="ml-2 float-right"
          :text="$t('button.save')"
          icon="mdi-content-save"
          icon-right
          :disabled="!canSave"
          @click="save"
        >
        </ResponsiveButton>
      </div>
    </template>
  </PageContent>
</template>

<script>
import AttributeApi from '@/api/attribute.js';
import Notification from '@/service/notification.js';

export default {
  name: 'AttributeDetails',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      attribute: {}
    };
  },
  computed: {
    canSave: function() {
      return Boolean(this.attribute) && Boolean(this.attribute.name);
    }
  },
  methods: {
    save() {
      if (!this.canSave) return;

      AttributeApi.createAttribute(this.attribute)
        .then(r => {
          this.attribute = r;
          Notification.success(this.$t('message.success.createAttribute'));
          this.navigateToList();
        })
        .catch(err => {
          Notification.error(this.$t('message.error.createAttribute'));
        });
    },
    navigateToList() {
      this.$router.push('/settings/attributes');
    }
  }
};
</script>
