<template>
  <v-dialog
    :value="showDialog"
    width="unset"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="fireCancelEvent"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('header.fileDialog') }}
      </v-card-title>
      <v-card-text>
        <v-file-input v-model="file" label="Datei auswählen"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="fireCancelEvent">
          <v-icon left>
            mdi-close-circle
          </v-icon>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="fireAcceptEvent">
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('button.replace') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'FileDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: undefined
    };
  },
  methods: {
    fireAcceptEvent() {
      this.$emit('acceptUpload', this.file);
      this.file = undefined;
      this.$emit('update:showDialog', false);
    },
    fireCancelEvent() {
      this.file = undefined;
      this.$emit('update:showDialog', false);
    }
  }
};
</script>
