<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('header.tenants') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-skeleton-loader
        v-if="fetchFinished != true"
        class="mx-auto"
        style="min-width: 100%; min-height: 100%"
        type="table:table-heading, table-thead, table-tbody"
      ></v-skeleton-loader>
      <div v-else>
        <Toolbar show-search @search="onSearch">
          <ToolbarAction
            icon="mdi-plus"
            :title="$t('button.newTenant')"
            @click="navigateToCreate"
          />
          <ToolbarAction
            icon="mdi-delete"
            :title="$t('button.delete')"
            :disabled="!selected"
            @click="showDeletionDialog"
          />
        </Toolbar>

        <v-data-table
          :items="tenants"
          :headers="headers"
          :search="search"
          item-key="id"
          show-select
          single-select
          @item-selected="onSelect"
        >
        </v-data-table>
      </div>

      <ConfirmationDialog
        :show-dialog.sync="showConfirmation"
        :text="$t('message.confirm.deleteTenant')"
        :title="$t('header.delete')"
        :accept-button-text="$t('button.yes')"
        :cancel-button-text="$t('button.no')"
        @onAccept="deleteTenant"
      ></ConfirmationDialog>
    </template>
  </PageContent>
</template>

<script>
import TenantApi from '@/api/tenant.js';
import Notification from '@/service/notification.js';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import Utils from '@/service/utils.js';
import Toolbar from '@/components/bars/Toolbar';
import ToolbarAction from '@/components/bars/ToolbarAction';

export default {
  name: 'TenantList',
  components: {
    ConfirmationDialog,
    Toolbar,
    ToolbarAction
  },
  data() {
    return {
      tenants: [],
      search: '',
      selected: null,
      showConfirmation: false,
      fetchFinished: false,
      headers: [
        {
          text: this.$t('model.name'),
          value: 'name'
        }
      ]
    };
  },
  mounted() {
    this.fetchTenants();
  },
  methods: {
    fetchTenants() {
      TenantApi.listTenants()
        .then(r => {
          if (r && r.items) {
            this.tenants = r.items;
          }

          this.tenants.sort(Utils.sortByPropertyName('name'));
          this.fetchFinished = true;
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchTenants'));
        });
    },
    onSearch(searchText) {
      this.search = searchText;
    },
    navigateToCreate() {
      this.$router.push('/tenants/new');
    },
    showDeletionDialog() {
      this.showConfirmation = true;
    },
    onSelect(event) {
      this.selected = event.item;
    },
    removeSelectedTenantFromList() {
      if (!this.selected || !this.tenants) return;

      const id = this.selected.id;

      let newTenants = this.users.filter(function(obj) {
        return obj.id !== id;
      });

      this.tenants = newTenants;
    },
    deleteTenant() {
      TenantApi.deleteTenant(this.selected.id)
        .then(r => {
          this.removeSelectedTenantFromList();
          this.selected = null;
          Notification.success(this.$t('message.success.deleteTenant'));
        })
        .catch(err => {
          Notification.error(this.$t('message.error.deleteTenant'));
        });
    }
  }
};
</script>
