import { client } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  getTenantDetails(tenantId) {
    return client
      .get(`/tenant/${tenantId}`, {
        headers: { 'x-rc-tenant-id': tenantId }
      })
      .then(response => response.data);
  },
  listTenants() {
    return client
      .get('/tenant', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  deleteTenant(id) {
    return client
      .delete(`/tenant/${id}`, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  registerTenant(form) {
    return client
      .post('/tenant', form, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
