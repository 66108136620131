<template>
  <form @submit.prevent="changePassword">
    <h2>
      {{ $t('header.changePassword') }}
    </h2>
    <!-- For password managers -->
    <v-text-field
      v-show="false"
      id="username"
      autocomplete="username"
    ></v-text-field>
    <PasswordField
      v-model="newPassword"
      autocomplete="new-password"
      :label="$t('model.password')"
    ></PasswordField>
    <v-btn :disabled="!newPassword" type="submit">
      <v-icon left>
        mdi-content-save
      </v-icon>
      {{ $t('button.change') }}
    </v-btn>
  </form>
</template>

<script>
import PasswordField from '@/components/inputs/PasswordField';

export default {
  name: 'ChangePassword',
  components: {
    PasswordField
  },
  data() {
    return {
      newPassword: '',
      showPassword: false
    };
  },
  methods: {
    changePassword() {
      if (this.newPassword) {
        this.$emit('changePassword', this.newPassword);
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>
