<template>
  <v-snackbar
    v-model="showSnackbar"
    :timeout="duration"
    bottom
    left
    :color="snackbarType"
  >
    <v-icon v-if="iconName">
      {{ iconName }}
    </v-icon>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        class="float-right"
        v-bind="attrs"
        @click="showSnackbar = false"
      >
        <v-icon>
          mdi-window-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { EventBus } from '@/service/event-bus.js';

export default {
  Name: 'Snackbar',
  data() {
    return {
      snackbarType: 'info',
      message: '',
      duration: 4000,
      showSnackbar: false
    };
  },
  computed: {
    iconName: function() {
      switch (this.snackType) {
        case 'danger':
          return 'error';
        case 'success':
          return 'check_circle';
        case 'info':
          return 'mdi-information-outline';
        default:
          return 'mdi-information-outline';
      }
    }
  },
  mounted() {
    EventBus.$on('danger', (message, duration) => {
      this.setSnackValues('danger', message, duration);
    });
    EventBus.$on('success', (message, duration) => {
      this.setSnackValues('success', message, duration);
    });
    EventBus.$on('info', (message, duration) => {
      this.setSnackValues('info', message, duration);
    });
    EventBus.$on('warning', (message, duration) => {
      this.setSnackValues('warning', message, duration);
    });
  },
  methods: {
    setSnackValues(snackbarType, message, duration) {
      this.snackbarType = snackbarType;
      this.message = message;
      this.duration = duration;
      this.showSnackbar = true;
    }
  }
};
</script>
