import { store } from '@/store/store.js';

function getIdToken() {
  if (
    store.getUser() &&
    store.getUser().signInUserSession &&
    store.getUser().signInUserSession.idToken &&
    store.getUser().signInUserSession.idToken.jwtToken
  )
    return store.getUser().signInUserSession.idToken.jwtToken;
  else {
    return undefined;
  }
}

export default {
  getHeaders() {
    let jwtToken = getIdToken();
    let headers = {};

    headers['Accept'] = 'application/json';
    headers['Cache-Control'] = 'max-age=0,no-cache,no-store,must-revalidate';

    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }

    return headers;
  }
};
