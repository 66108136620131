import { client, clientWithoutSpinner } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  listIdentities(withoutSpinner) {
    if (withoutSpinner) {
      return clientWithoutSpinner
        .get('/tenant/identity', {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    } else {
      return client
        .get('/tenant/identity', {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    }
  },
  listRoles() {
    return client
      .get('/tenant/identity/current-user/roles', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getIdentityDetails(id) {
    return client
      .get(`/tenant/identity/${id}`, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getIdentity(id) {
    return client
      .get(`/document/identity/${id}`, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  addIdentity(user) {
    return client
      .post('/tenant/identity', user, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  updateIdentity(identity) {
    return client
      .patch(`/tenant/identity/${identity.id}` + identity.userName, identity, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  deleteTenantRole(id) {
    return client
      .delete(`/tenant/identity/${id}`, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getCurrentUser() {
    return client
      .get('/tenant/identity/current-user')
      .then(response => response.data);
  },
  getTenantRolesForCurrentUser() {
    return client
      .get('/tenant/identity/current-user/tenants')
      .then(response => response.data);
  },
  updateCurrentUser(user) {
    return client
      .put('/tenant/identity/current-user', user)
      .then(response => response.data);
  },
  addAttribute(attribute) {
    return client
      .post(
        `/document/identity/${attribute.identityId}/attributes`,
        attribute,
        {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        }
      )
      .then(response => response.data);
  },
  deleteAttribute(identityId, attributeId) {
    return client
      .delete(`/document/identity/${identityId}/attributes/${attributeId}`, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
