import { client } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  listReadDocTypesForIdentity() {
    return client
      .get('/document/permission/doc-types/read', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  listWriteDocTypesForIdentity() {
    return client
      .get('/document/permission/doc-types/write', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
