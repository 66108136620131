<template>
  <PageContent
    :show-side-content="currentDocumentComplete && showPreview"
    :size-side-content="4"
  >
    <template v-slot:header>
      <h2>
        {{ $t('header.documentUpload') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-container
        style="height: 100%"
        :class="{
          'red-border': dragover && showFileSelection,
          'background-image': showFileSelection && items.length < 1
        }"
        @drop.stop.prevent="onDrop"
        @dragover.prevent
        @dragenter="onDrag"
        @dragleave="onDragLeave"
      >
        <multi-file-form
          ref="fileForm"
          v-if="!isExistingDocument && showFileSelection"
          :items="items"
          @add="addItem"
          @delete="deleteItem"
        ></multi-file-form>

        <document-form
          v-if="!isLoading && currentDocumentComplete && !showUploadProgress"
          :key="step"
          v-model="currentDocument"
          :doc-types="docTypes"
          :show-preview.sync="showPreview"
          :is-valid.sync="isValidDocument"
          :selected-doc-type.sync="selectedDocType"
          :pinned-props.sync="pinnedProps"
          :showPins="items.length > 1"
        ></document-form>

        <DocumentProgress v-if="showUploadProgress" :documents="items">
        </DocumentProgress>

        <DocumentViewerDialog
          v-if="!showFileSelection && currentDocumentComplete"
          :show-dialog.sync="showDocumentDialog"
          :file-type="currentFile ? currentFile.type : ''"
          :file-url="currentUrl"
        ></DocumentViewerDialog>
      </v-container>
    </template>
    <template v-slot:actions>
      <div class="d-flex justify-space-between">
        <ResponsiveButton
          icon="mdi-close-circle"
          icon-left
          :text="$t('button.cancel')"
          @click="navigateToList"
        >
        </ResponsiveButton>
        <div v-if="step === -1">
          {{ $t('message.info.documentSelectCount', { number: items.length }) }}
        </div>
        <div v-else-if="items.length > 1 && !showUploadProgress">
          {{
            $t('message.info.documentPage', {
              current: step + 1,
              max: items.length
            })
          }}
        </div>
        <div class="float-right">
          <ResponsiveButton
            v-if="step !== -1 && !isExistingDocument && !showUploadProgress"
            icon=" mdi-arrow-left"
            :text="$t('button.back')"
            icon-left
            @click="previousPage"
          >
          </ResponsiveButton>
          <ResponsiveButton
            v-if="
              step === items.length - 1 &&
                items.length > 0 &&
                !showUploadProgress
            "
            color="primary"
            class="ml-2 float-right"
            :text="$t('button.save')"
            icon="mdi-content-save"
            icon-right
            :disabled="!canProgress"
            @click="save"
          >
          </ResponsiveButton>
          <ResponsiveButton
            v-else-if="!showUploadProgress"
            :text="$t('button.next')"
            icon-right
            icon="mdi-arrow-right"
            color="primary"
            class="ml-2"
            :disabled="!canProgress"
            @click="nextPage"
          >
          </ResponsiveButton>
          <ResponsiveButton
            v-else
            color="primary"
            icon=" mdi-backspace-reverse"
            :text="$t('button.close')"
            icon-left
            :disabled="!allDocsFinished"
            @click="navigateToList"
          >
          </ResponsiveButton>
        </div>
      </div>
    </template>
    <template v-slot:content-side>
      <div v-if="currentDocumentComplete && showPreview" class="fill-height">
        <v-card class="fill-height d-flex flex-column">
          <v-card-title>
            {{ $t('model.document') }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              :title="$t('button.show')"
              @click="showDocumentViewerDialog"
            >
              <v-icon> mdi-file-eye </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="fill-height px-2 py-3" style="overflow: auto">
            <document-viewer
              :url="currentUrl"
              :mime-type="currentFile.type"
            ></document-viewer>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </PageContent>
</template>

<script>
import DocumentApi from '@/api/document.js';
import S3Api from '@/api/s3.js';
import Notification from '@/service/notification';
import PermissionApi from '@/api/permission.js';
import Utils from '@/service/utils.js';
import DocumentViewer from '@/components/viewer/DocumentViewer.vue';
import DocumentViewerDialog from '@/components/dialogs/DocumentViewerDialog.vue';
import DocumentForm from '@/components/forms/DocumentForm.vue';
import DocumentProgress from '@/components/forms/DocumentProgress.vue';
import MultiFileForm from '@/components/forms/MultiFileForm.vue';

export default {
  name: 'DocumentDetails',
  components: {
    DocumentViewer,
    DocumentViewerDialog,
    DocumentForm,
    MultiFileForm,
    DocumentProgress
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      items: [],
      selectedDocType: {},
      docTypes: [],
      pinnedProps: {},
      showDocumentDialog: false,
      showPreview: false,
      showFileSelection: true,
      showUploadProgress: false,
      isLoading: true,
      isValidDocument: false,
      step: -1,
      dragover: false
    };
  },
  computed: {
    canProgress: function() {
      if (this.showFileSelection && this.items.length > 0) {
        return true;
      } else if (this.isValidDocument) {
        return true;
      }
      return false;
    },
    currentDocumentComplete: function() {
      return (
        !this.showFileSelection &&
        Boolean(this.items) &&
        Boolean(this.items[this.step]) &&
        Boolean(this.items[this.step].document) &&
        Boolean(this.items[this.step].file) &&
        Boolean(this.items[this.step].url)
      );
    },
    currentDocument: function() {
      if (this.currentDocumentComplete) {
        return this.items[this.step].document;
      }
      return null;
    },
    currentFile: function() {
      if (this.currentDocumentComplete) {
        return this.items[this.step].file;
      }
      return null;
    },
    currentUrl: function() {
      if (this.currentDocumentComplete && this.items[this.step].url) {
        return this.items[this.step].url;
      }
      return null;
    },
    canSave: function() {
      return true;
    },
    isExistingDocument: function() {
      return Boolean(this.currentDocument) && Boolean(this.currentDocument.id);
    },
    allDocsFinished: function() {
      if (!this.items) return false;

      for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i].status || this.items[i].status === 'loading')
          return false;
      }

      return true;
    }
  },
  mounted() {
    if (this.id) {
      this.fetchDocument();
    } else {
      this.fetchDocTypes();
    }
  },
  methods: {
    previousPage() {
      if (this.step === -1) {
        return;
      }

      this.step -= 1;

      if (this.step === -1) {
        this.showFileSelection = true;
      }
    },
    nextPage() {
      if (this.step === this.items.length) return;

      this.step += 1;

      if (this.step === 0) {
        this.showFileSelection = false;
        this.showPreview = true;
      }

      if (this.step > 0) {
        console.log('set pins');

        if (this.pinnedProps) {
          for (const [key, value] of Object.entries(this.pinnedProps)) {
            if (key === 'title') {
              if (!this.currentDocument.standardProperties.title) {
                this.currentDocument.standardProperties.title = value;
              }
            } else {
              if (!this.currentDocument.individualProperties[key]) {
                this.currentDocument.individualProperties[key] = value;
              }
            }
          }
        }
      }
      console.log('current doc: ', this.currentDocument);
    },
    showDocumentViewerDialog() {
      this.showDocumentDialog = true;
    },
    onDrag() {
      this.dragover = true;
    },
    onDragLeave() {
      this.dragover = false;
    },
    onDrop(e) {
      console.log('dropped: ', e);
      this.dragover = false;

      if (this.step !== -1) return;

      if (e && e.dataTransfer && e.dataTransfer.files) {
        this.$refs.fileForm.addFiles(e.dataTransfer.files);
      }
    },
    addItem(event) {
      console.log('add file event: ', event);
      if (!event) return;

      let item = {
        document: {
          individualProperties: {},
          standardProperties: {
            file: {
              contentType: event.type,
              fileName: event.name,
              fileSizeInByte: event.size
            }
          }
        },
        file: event,
        url: URL.createObjectURL(event)
      };

      this.items.push(item);
    },
    deleteItem(event) {
      console.log('delete file event: ', event);
      if (!event) return;

      let indexToDelete = this.items.findIndex(
        item => item.file.name === event.name
      );

      console.log('index to delete: ', indexToDelete);

      if (indexToDelete > -1) {
        this.items.splice(indexToDelete, 1);
      }
    },
    save() {
      if (!this.canSave) return;

      if (this.isExistingDocument) {
        this.updateDocument();
      } else {
        this.createDocument();
      }
    },
    fetchDocTypes() {
      this.doFetchDocTypes()
        .then(() => {
          if (this.docTypes && this.docTypes.length === 1) {
            this.selectedDocType = this.docTypes[0];
          }
        })
        .catch(() => Notification.error(this.$t('message.error.fetchDocTypes')))
        .finally(() => (this.isLoading = false));
    },
    doFetchDocTypes() {
      return new Promise((resolve, reject) => {
        PermissionApi.listWriteDocTypesForIdentity()
          .then(r => {
            if (r.items && r.items.length > 0) {
              this.docTypes = r.items;
              this.docTypes.sort(Utils.sortByPropertyName('name'));
            }
            resolve(r);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    createDocument() {
      Notification.inProgress(true);
      this.showPreview = false;
      this.showUploadProgress = true;

      for (let i = 0; i < this.items.length; i++) {
        const doc = this.items[i];
        this.$set(this.items[i], 'status', 'loading');

        DocumentApi.uploadDocument(doc.document, true)
          .then(r => {
            let config = {
              headers: {
                'Content-Disposition': r.contentDisposition,
                'Content-Type': doc.document.standardProperties.file.contentType
              }
            };

            S3Api.putObject(r.url, doc.file, config)
              .then(r => {
                console.info('krass, Datei in S3: ', r);
                this.$set(this.items[i], 'status', 'success');
                Notification.success(this.$t('message.success.uploadDocument'));
              })
              .catch(err => {
                console.error('error uploading file to s3: ', err);
                this.$set(this.items[i], 'status', 'error');
                Notification.error(this.$t('message.error.uploadDocument'));
              });
          })
          .catch(err => {
            if (err.response && err.response.status === 403) {
              Notification.error(
                this.$t('message.error.insufficientPermission')
              );
            } else {
              Notification.error(this.$t('message.error.uploadDocument'));
            }
            this.$set(this.items[i], 'status', 'error');
          });
      }

      Notification.inProgress(false);
    },
    fetchDocument() {
      this.doFetchDocTypes()
        .then(docTypes => {
          console.log('types response: ', docTypes);
          DocumentApi.getDocument(this.id)
            .then(doc => {
              console.log('doc response: ', doc);
              DocumentApi.getFileDownloadLink(this.id, true)
                .then(file => {
                  console.debug('file response: ', file);

                  this.selectedDocType = docTypes.items.find(
                    dt => dt.id === doc.docTypeId
                  );

                  let item = {
                    document: doc,
                    file: {}
                  };

                  if (doc.standardProperties && doc.standardProperties.file) {
                    item.file = {
                      type: doc.standardProperties.file.contentType,
                      name: doc.standardProperties.file.fileName,
                      size: doc.standardProperties.file.fileSizeInByte
                    };
                  }

                  if (!item.document.individualProperties) {
                    item.document.individualProperties = {};
                  }

                  if (file && file.url) {
                    item.url = file.url;
                  }

                  this.items.push(item);
                  this.step = 0;
                  this.showFileSelection = false;
                })
                .catch(err => {
                  console.error('err: ', err);
                  Notification.error(this.$t('message.error.showDocument'));
                });
            })
            .catch(err => {
              console.error('err: ', err);
              Notification.error(this.$t('message.error.fetchDocument'));
            });
        })
        .catch(err => {
          console.error('err: ', err);
          Notification.error(this.$t('message.error.fetchDocTypes'));
        })
        .finally(() => (this.isLoading = false));
    },
    updateDocument() {
      DocumentApi.putDocument(this.currentDocument.id, this.currentDocument)
        .then(r => {
          Notification.success(this.$t('message.success.editDocument'));
          this.navigateToList();
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            Notification.error(this.$t('message.error.insufficientPermission'));
          } else {
            Notification.error(this.$t('message.error.editDocument'));
          }
        });
    },
    navigateToList() {
      this.$router.push('/documents');
    }
  }
};
</script>

<style scoped>
.red-border {
  border-color: var(--v-primary-base);
  border-style: dashed;
  border-width: 1px;
}

.background-image {
  background: url('../../../assets/cloud-upload.png');
  background-repeat: no-repeat;
  background-position: center;
}
</style>
