<template>
  <v-app-bar app color="secondary" clipped-left>
    <v-app-bar-nav-icon
      v-if="loggedIn"
      @click="changeDrawerStatus"
    ></v-app-bar-nav-icon>
    <v-img
      v-if="!$vuetify.breakpoint.mobile"
      alt="Logo"
      class="shrink mr-2"
      contain
      src="@/assets/rc_logo.png"
      transition="scale-transition"
      width="40"
      height="40"
    />
    <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
      {{ title }}
    </v-toolbar-title>
    <v-spacer v-if="envAppendix && !$vuetify.breakpoint.xs"></v-spacer>
    <div v-if="envAppendix && !$vuetify.breakpoint.xs" class="appendix">
      <h2>{{ envAppendix }}</h2>
    </div>
    <v-spacer></v-spacer>
    <quick-tenant-selection v-if="loggedIn"></quick-tenant-selection>
    <user-menu v-if="loggedIn"></user-menu>
    <v-btn v-else to="/login" class="ma-2" tile outlined>
      <v-icon left>
        mdi-login
      </v-icon>
      {{ $t('button.login') }}
    </v-btn>
  </v-app-bar>
</template>

<script>
import { store, mutations } from '@/store/store.js';
import QuickTenantSelection from '@/components/menus/QuickTenantSelection.vue';
import UserMenu from '@/components/menus/UserMenu.vue';

export default {
  name: 'NavBar',
  components: {
    QuickTenantSelection,
    UserMenu
  },
  computed: {
    loggedIn: function() {
      return Boolean(store.getUser());
    },
    title: function() {
      return store.getTitle();
    },
    envAppendix: function() {
      return process.env.VUE_APP_ENV_APPENDIX;
    }
  },
  methods: {
    changeDrawerStatus() {
      mutations.setDrawerState(!store.getDrawerState());
    }
  }
};
</script>

<style scoped>
.appendix {
  color: #dc291e;
}
</style>
