import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import themes from './themes';
import i18n from '@/i18n/i18n_vuetify.js';

Vue.use(Vuetify);

console.log('i18n: ', i18n);

export default new Vuetify({
  theme: {
    themes: {
      light: themes.light,
      dark: themes.dark
    },
    options: {
      customProperties: true
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});
