<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('header.docProperties') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-skeleton-loader
        v-if="fetchFinished != true"
        class="mx-auto"
        style="min-width: 100%; min-height: 100%"
        type="table:table-heading, table-thead, table-tbody"
      ></v-skeleton-loader>
      <div v-else>
        <Toolbar show-search @search="onSearch">
          <ToolbarAction
            icon="mdi-plus"
            :title="$t('button.newDocProp')"
            @click="navigateToCreate"
          />
          <ToolbarAction
            icon="mdi-delete"
            :title="$t('button.delete')"
            :disabled="!selected"
            @click="showDeletionDialog"
          />
        </Toolbar>

        <v-data-table
          :items="properties"
          :headers="headers"
          :search="search"
          item-key="id"
          show-select
          single-select
          @item-selected="onSelect"
        >
          <template v-slot:item.type="{ item }">
            {{ item.type | propertyType }}
          </template>
        </v-data-table>
      </div>

      <ConfirmationDialog
        :show-dialog.sync="showConfirmation"
        :text="$t('message.confirm.deleteDocProperty')"
        :title="$t('header.delete')"
        :accept-button-text="$t('button.yes')"
        :cancel-button-text="$t('button.no')"
        @onAccept="deleteProperty"
      ></ConfirmationDialog>
    </template>
  </PageContent>
</template>

<script>
import DocPropertyApi from '@/api/docProperty.js';
import Notification from '@/service/notification.js';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import Utils from '@/service/utils.js';
import Toolbar from '@/components/bars/Toolbar';
import ToolbarAction from '@/components/bars/ToolbarAction';

export default {
  name: 'PropertyList',
  components: {
    Toolbar,
    ToolbarAction,
    ConfirmationDialog
  },
  data() {
    return {
      properties: [],
      selected: null,
      showConfirmation: false,
      fetchFinished: false,
      search: '',
      headers: [
        {
          text: this.$t('model.name'),
          value: 'name'
        },
        {
          text: this.$t('model.description'),
          value: 'description'
        },
        {
          text: this.$t('model.type'),
          value: 'type'
        }
      ]
    };
  },
  mounted() {
    this.fetchProperties();
  },
  methods: {
    fetchProperties() {
      DocPropertyApi.getProperties()
        .then(r => {
          if (r.items && r.items.length > 0) {
            this.properties = r.items;
            this.properties.sort(Utils.sortByPropertyName('name'));
          }
          this.fetchFinished = true;
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocProperties'));
        });
    },
    onSelect(event) {
      this.selected = event.item;
    },
    onSearch(searchText) {
      this.search = searchText;
    },
    showDeletionDialog() {
      this.showConfirmation = true;
    },
    navigateToDetails() {
      if (!this.selected || !this.selected.id) return;
      this.$router.push('/settings/properties/' + this.selected.id);
    },
    navigateToCreate() {
      this.$router.push('/settings/properties/new');
    },
    deleteProperty() {
      if (!this.selected || !this.selected.id) return;

      DocPropertyApi.deleteProperty(this.selected.id)
        .then(() => {
          Notification.success(this.$t('message.success.deleteDocProperty'));
          this.removeSelectedPropertyFromList();
        })
        .catch(err => {
          Notification.error(this.$t('message.error.deleteDocProperty'));
        });
    },
    removeSelectedPropertyFromList() {
      if (!this.selected || !this.properties) return;

      const id = this.selected.id;

      let newProps = this.properties.filter(function(obj) {
        return obj.id !== id;
      });

      this.properties = newProps;
      this.selected = null;
    }
  }
};
</script>
