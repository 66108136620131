<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('header.docPropertyDetails') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-form class="px-2">
        <v-text-field
          v-model="property.name"
          :label="$t('model.name') + '*'"
        ></v-text-field>
        <v-text-field
          v-model="property.nameEnglish"
          :label="$t('model.nameEnglish')"
        ></v-text-field>
        <v-textarea
          v-model="property.description"
          :label="$t('model.description')"
          auto-grow
          rows="1"
        ></v-textarea>
        <v-textarea
          v-model="property.descriptionEnglish"
          :label="$t('model.descriptionEnglish')"
          auto-grow
          rows="1"
        ></v-textarea>
        <v-select
          v-model="property.type"
          :items="types"
          item-text="name"
          item-value="value"
          :label="$t('model.type')"
        ></v-select>
      </v-form>
    </template>
    <template v-slot:actions>
      <div class="float-right">
        <ResponsiveButton
          icon="mdi-close-circle"
          icon-left
          :text="$t('button.cancel')"
          @click="navigateToList"
        >
        </ResponsiveButton>
        <ResponsiveButton
          color="primary"
          class="ml-2 float-right"
          :text="$t('button.save')"
          icon="mdi-content-save"
          icon-right
          :disabled="!canSave"
          @click="save"
        >
        </ResponsiveButton>
      </div>
    </template>
  </PageContent>
</template>

<script>
import DocPropertyApi from '@/api/docProperty.js';
import Notification from '@/service/notification.js';

export default {
  name: 'PropertyDetails',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      property: {
        type: 'string'
      },
      types: [
        {
          name: this.$t('model.docPropType_string'),
          value: 'string'
        },
        {
          name: this.$t('model.docPropType_date'),
          value: 'date'
        },
        {
          name: this.$t('model.docPropType_numeric'),
          value: 'numeric'
        },
        {
          name: this.$t('model.docPropType_decimal'),
          value: 'decimal'
        },
        {
          name: this.$t('model.docPropType_boolean'),
          value: 'boolean'
        }
      ]
    };
  },
  computed: {
    canSave: function() {
      return Boolean(this.property) && Boolean(this.property.name);
    }
  },
  mounted() {
    this.fetchProperty();
  },
  methods: {
    fetchProperty() {
      if (this.id === 'new') return;

      DocPropertyApi.getProperty(this.id)
        .then(r => {
          if (r) {
            this.property = r;
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocProperty'));
        });
    },
    save() {
      if (!this.canSave) return;

      if (!this.property.id) {
        DocPropertyApi.addProperty(this.property)
          .then(r => {
            this.property = r;
            Notification.success(this.$t('message.success.createDocProperty'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.createDocProperty'));
          });
      } else {
        DocPropertyApi.putProperty(this.property)
          .then(r => {
            this.property = r;
            Notification.success(this.$t('message.success.editDocProperty'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.editDocProperty'));
          });
      }
    },
    navigateToList() {
      this.$router.push('/settings/properties');
    }
  }
};
</script>
