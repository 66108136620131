<template>
  <v-menu open-on-click bottom left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-if="user && user.attributes" class="title">
            {{ user.attributes.name }}
          </v-list-item-title>
          <br />

          <v-list-item-subtitle v-if="user && user.attributes">
            <v-icon small>mdi-email</v-icon> {{ user.attributes.email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="tenant">
            <v-icon small>mdi-domain</v-icon> {{ tenant.name }} ({{
              tenant.role | roleName
            }})
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <IconList :items="accountMenu"></IconList>
  </v-menu>
</template>

<script>
import IconList from '@/components/lists/IconList.vue';
import { store } from '@/store/store.js';

export default {
  name: 'UserMenu',
  components: {
    IconList
  },
  data() {
    return {
      accountMenu: [
        {
          to: '/profile',
          icon: 'mdi-account',
          title: this.$t('navigation.profile')
        },
        {
          to: '/tenant/selection',
          icon: 'mdi-domain',
          title: this.$t('navigation.selectTenant')
        },
        {
          to: '/logout',
          icon: 'mdi-power-standby',
          title: this.$t('navigation.logout')
        }
      ]
    };
  },
  computed: {
    tenant: function() {
      return store.getTenant();
    },
    user: function() {
      return store.getUser();
    }
  }
};
</script>
