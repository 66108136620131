<template>
  <PageContent>
    <template v-slot:header>
      <h2>{{ $t('header.changePassword') }}</h2>
    </template>
    <template v-slot:content>
      <div>
        <!-- For password managers -->
        <v-text-field
          v-show="false"
          id="username"
          autocomplete="username"
        ></v-text-field>
        <v-text-field
          v-model="oldPassword"
          :label="$t('model.oldPassword')"
          type="password"
          autocomplete="old-password"
        ></v-text-field>
        <PasswordField
          v-model="newPassword"
          autocomplete="new-password"
          :label="$t('model.newPassword')"
        ></PasswordField>
      </div>
    </template>
    <template v-slot:actions>
      <ResponsiveButton
        icon="mdi-close-circle"
        icon-left
        :text="$t('button.cancel')"
        @click="navigateToProfile"
      >
      </ResponsiveButton>
      <ResponsiveButton
        color="primary"
        class="ml-2 float-right"
        :text="$t('button.save')"
        icon="mdi-content-save"
        icon-right
        @click="save"
      >
      </ResponsiveButton>
    </template>
  </PageContent>
</template>

<script>
import AuthService from '@/service/auth';
import { mutations, store } from '@/store/store.js';
import Notification from '@/service/notification';
import PasswordField from '@/components/inputs/PasswordField';

export default {
  name: 'Password',
  components: {
    PasswordField
  },
  data() {
    return {
      oldPassword: '',
      newPassword: ''
    };
  },
  methods: {
    navigateToProfile() {
      this.$router.push('/profile');
    },
    save() {
      if (!this.oldPassword || !this.newPassword) return;

      Notification.inProgress(true);
      AuthService.changePassword(
        store.getUser(),
        this.oldPassword,
        this.newPassword
      )
        .then(user => {
          console.info('changing password successful: ', user);
          Notification.success(this.$t('message.success.changePassword'));

          if (user.signInUserSession) {
            mutations.setUser(user);
          }

          this.$router.push('/profile');
        })
        .catch(error => {
          console.error('error on changing password: ', error);
          Notification.error(this.$t('message.error.changePassword'));
        })
        .finally(() => Notification.inProgress(false));
    }
  }
};
</script>
