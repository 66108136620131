<template>
  <div>
    <v-file-input
      ref="fileInput"
      :label="$t('model.addFile') + '*'"
      multiple
      single-line
      @change="addFiles"
    >
    </v-file-input>
    {{ $t('model.selectedFiles') }}:
    <v-list flat>
      <v-list-item v-for="(item, index) in items" :key="index">
        {{ index + 1 }}. {{ item.file.name }}
        <v-spacer></v-spacer>

        <span>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="emitDeleteEvent(item.file, index)"
              >
                <v-icon>> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.delete') }}</span>
          </v-tooltip>
        </span>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import Notification from '@/service/notification';

export default {
  name: 'MultiFileForm',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    addFiles(val) {
      if (val && val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          console.log('file: ', val[i]);
          if (this.items.find(obj => obj.file.name === val[i].name)) {
            //duplicate
            continue;
          }

          if (val[i].size === 0) {
            Notification.error(this.$t('message.error.fileIsEmpty'));
            continue;
          }

          if (val[i].size > 25000000) {
            Notification.error(this.$t('message.error.fileSizeToBig'));
            continue;
          }

          this.emitAddEvent(val[i]);
        }

        this.$refs.fileInput.reset();
      }
    },
    emitAddEvent(val) {
      this.$emit('add', val);
    },
    emitDeleteEvent(val, index) {
      this.$emit('delete', val);
    }
  }
};
</script>
