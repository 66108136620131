<template>
  <v-menu open-on-click bottom left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-icon v-if="isError" color="orange">
          mdi-alert
        </v-icon>
        <span v-if="currTenant && currTenant.id">
          {{ currTenant.name }} ({{ currTenant.role | roleName }})
        </span>
        <span v-else>
          {{ $t('message.info.selectTenant') }}
        </span>
        <v-icon v-if="isLoading">
          mdi-loading mdi-spin
        </v-icon>
        <v-icon v-else>
          mdi-chevron-down
        </v-icon>
      </div>
    </template>
    <v-list v-if="!isLoading && !isError">
      <v-list-item
        v-for="(tenant, index) in tenantRoles"
        :key="index"
        link
        @click="selectTenant(tenant)"
      >
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ tenant.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            ({{ tenant.role | roleName }})
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card v-else-if="isError">
      <v-card-text>
        {{ $t('message.error.fetchUserTenants') }}
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import IdentityApi from '@/api/identity.js';
import { store, mutations } from '@/store/store.js';

export default {
  name: 'QuickTenantSelection',
  data() {
    return {
      isLoading: true,
      isError: false,
      tenantRoles: []
    };
  },
  computed: {
    currTenant: function() {
      return store.getTenant();
    }
  },
  mounted() {
    this.fetchAvailableTenants();
  },
  methods: {
    fetchAvailableTenants() {
      IdentityApi.getTenantRolesForCurrentUser()
        .then(result => {
          if (result && result.tenants) {
            for (let i = 0; i < result.tenants.length; i++) {
              this.tenantRoles.push(result.tenants[i]);
            }
          }
        })
        .catch(err => {
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectTenant(tenant) {
      if (!this.currTenant || this.currTenant.id !== tenant.id) {
        mutations.setTenant(tenant);
        location.reload();
      }
    }
  }
};
</script>
