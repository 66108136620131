import { EventBus } from '@/service/event-bus.js';

export default {
  error(message, duration) {
    EventBus.$emit('danger', message, duration);
  },
  success(message, duration) {
    EventBus.$emit('success', message, duration);
  },
  info(message, duration) {
    EventBus.$emit('info', message, duration);
  },
  warning(message, duration) {
    EventBus.$emit('warning', message, duration);
  },
  inProgress(inProgress) {
    EventBus.$emit('inProgress', inProgress);
  }
};
