import axios from 'axios';
import defaultConfig from './axios-config';
import Notification from '@/service/notification';

const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API
});

const clientWithoutSpinner = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API
});

function setRequestInterceptor(instance, showSpinner) {
  instance.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      console.time(config.method + config.url);

      var defaultHeaders = defaultConfig.getHeaders();
      config.headers['Authorization'] = defaultHeaders['Authorization'];
      config.headers['Accept'] = defaultHeaders['Accept'];
      config.headers['Cache-Control'] = defaultHeaders['Cache-Control'];

      if (showSpinner) {
        Notification.inProgress(true);
      }
      return config;
    },
    function(error) {
      // Do something with request error
      console.error('request error: ', error);
      if (showSpinner) {
        Notification.inProgress(false);
      }
      return Promise.reject(error);
    }
  );
}

function setResponseInterceptor(instance, showSpinner) {
  instance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (response) {
        if (response.config) {
          console.info(
            `SUCCESS ${response.config.method}, ${response.config.url}, ${response.status}: `,
            response.data
          );
          console.timeEnd(response.config.method + response.config.url);
        } else {
          console.info(`SUCCESS ${response.status}: `, response.data);
        }
      }

      if (showSpinner) {
        Notification.inProgress(false);
      }
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error) {
        if (error.config && error.response) {
          console.error(
            `ERROR ${error.config.method}, '${error.config.url}, ${error.response.status}: `,
            error.response.data
          );
          console.timeEnd(error.config.method + error.config.url);
        } else if (error.config) {
          console.error(
            `ERROR ${error.config.method}, '${error.config.url}: `,
            error
          );
        } else {
          console.error('ERROR', error);
        }
      }

      if (showSpinner) {
        Notification.inProgress(false);
      }
      return Promise.reject(error);
    }
  );
}

setRequestInterceptor(client, true);
setResponseInterceptor(client, true);

setRequestInterceptor(clientWithoutSpinner, false);
setResponseInterceptor(clientWithoutSpinner, false);

export { client, clientWithoutSpinner };
