import Amplify, { Auth } from 'aws-amplify';
import { mutations } from '@/store/store';

var isAuthConfigured = false;
var defaultConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',

    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID
  }
};

async function configureAmplify() {
  if (isAuthConfigured) return;

  try {
    console.log('defaultCOnfig: ', defaultConfig);
    Amplify.configure(defaultConfig);
    isAuthConfigured = true;
    console.info('successfully configured amplify: ', defaultConfig);
  } catch (error) {
    isAuthConfigured = true;
    console.error('error configuring: ', error);
  }
}

export default {
  async loginUser(email, password) {
    if (!isAuthConfigured) await configureAmplify();

    if (!isAuthConfigured) return;

    return Auth.signIn(email, password);
  },
  logOutUser() {
    return Auth.signOut();
  },
  completePassword(user, newPassword) {
    return Auth.completeNewPassword(user, newPassword);
  },
  resetPassword(email) {
    return Auth.forgotPassword(email);
  },
  resetPasswordSubmit(email, resetCode, newPassword) {
    return Auth.forgotPasswordSubmit(email, resetCode, newPassword);
  },
  sendMailVerification() {
    return Auth.verifyCurrentUserAttribute('email');
  },
  changePassword(user, oldPassword, newPassword) {
    return Auth.changePassword(user, oldPassword, newPassword);
  },
  verifyMail(code) {
    return Auth.verifyCurrentUserAttributeSubmit('email', code);
  },
  updateUserAttributes(user, attributes) {
    return Auth.updateUserAttributes(user, attributes);
  },
  async updateCurrentUser(bypassCache) {
    if (!bypassCache) bypassCache = false;

    if (!isAuthConfigured) await configureAmplify();
    if (!isAuthConfigured) return;

    let userInfo;

    try {
      userInfo = await Auth.currentAuthenticatedUser({
        bypassCache: bypassCache
      });
      console.debug('current user updated: ', userInfo);
      mutations.setUser(userInfo);
    } catch (error) {
      console.debug('User is not authenticated');
      mutations.setUser(null);
    }
  }
};
