<template>
  <v-text-field
    :value="value"
    :autocomplete="autocomplete"
    :label="label"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    @input="emitInputEvent"
    @click:append="togglePassword"
    :rules="rules"
  ></v-text-field>
</template>

<script>
export default {
  name: 'PasswordField',
  props: {
    value: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'password'
    },
    label: {
      type: String,
      default: () => this.$t('model.password')
    }
  },
  data() {
    return {
      showPassword: false,
      rules: [
        value => !!value || this.$t('message.error.required'),
        value =>
          (value || '').length >= 8 ||
          this.$t('message.error.minimumCharacters', { value: 8 }),
        value => {
          const pattern = /.*[0-9].*/;
          return pattern.test(value) || this.$t('message.error.containNumber');
        },
        value => {
          const pattern = /.*[A-Z].*/;
          return (
            pattern.test(value) || this.$t('message.error.containUpperCase')
          );
        }
      ]
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    emitInputEvent(event) {
      this.$emit('input', event);
    }
  }
};
</script>
