<template>
  <v-form :disabled="disabled" class="px-2">
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          :value="selectedDocType"
          :items="docTypes"
          :item-text="localizedName"
          return-object
          :label="$t('model.selectDocType') + '*'"
          @change="setDocType"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="value.standardProperties.file.fileName"
          :label="$t('model.fileName')"
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              icon
              :color="showPreview ? 'primary' : ''"
              @click="togglePreview"
            >
              <v-icon> mdi-view-split-vertical </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <div v-if="Boolean(selectedDocType) && selectedDocType.id">
      <h4>{{ $t('model.properties') }}</h4>
      <v-text-field
        v-model="value.standardProperties.title"
        :label="$t('model.title') + '*'"
        required
        @input="formChanged"
      >
        <template v-slot:append>
          <pin
            v-if="showPins"
            :value="
              pinnedProps.title != null &&
                pinnedProps.title === value.standardProperties.title
            "
            @input="
              pinnedPropsUpdate('title', $event, value.standardProperties.title)
            "
          ></pin>
        </template>
      </v-text-field>

      <div v-for="(property, index) in selectedDocType.properties" :key="index">
        <v-radio-group
          v-if="property.property.type === 'boolean'"
          v-model="value.individualProperties[property.property.id]"
          row
          :label="getPropLabel(property)"
          @input="formChanged"
        >
          <v-radio :label="$t('model.yes')" value="true"></v-radio>
          <v-radio :label="$t('model.no')" value="false"></v-radio>
          <template v-slot:append>
            <pin
              v-if="showPins"
              :value="
                pinnedProps[property.property.id] != null &&
                  pinnedProps[property.property.id] ===
                    value.individualProperties[property.property.id]
              "
              @input="
                pinnedPropsUpdate(
                  property.property.id,
                  $event,
                  value.individualProperties[property.property.id]
                )
              "
            ></pin>
          </template>
        </v-radio-group>

        <!-- :run => Hack, um das v-model zu initialisieren -->
        <v-text-field
          v-else-if="
            property.restrictionValues &&
              property.restrictionValues.length === 1
          "
          v-model="value.individualProperties[property.property.id]"
          :run="
            (value.individualProperties[property.property.id] =
              property.restrictionValues[0])
          "
          :label="getPropLabel(property)"
          :type="getInputType(property.property.type)"
          :readonly="true"
          @input="formChanged"
        >
        </v-text-field>

        <v-select
          v-else-if="
            property.restrictionValues && property.restrictionValues.length > 1
          "
          v-model="value.individualProperties[property.property.id]"
          :items="property.restrictionValues"
          :label="getPropLabel(property)"
          type="text"
          @input="formChanged"
        >
          {{ property.property.type | propertyTypeIcon }}
          <template v-slot:append>
            <pin
              v-if="showPins"
              :value="
                pinnedProps[property.property.id] != null &&
                  pinnedProps[property.property.id] ===
                    value.individualProperties[property.property.id]
              "
              @input="
                pinnedPropsUpdate(
                  property.property.id,
                  $event,
                  value.individualProperties[property.property.id]
                )
              "
            ></pin>
          </template>
        </v-select>

        <v-text-field
          v-else
          v-model="value.individualProperties[property.property.id]"
          :label="getPropLabel(property)"
          :type="getInputType(property.property.type)"
          @input="formChanged"
        >
          <template v-slot:append>
            <pin
              v-if="showPins"
              :value="
                pinnedProps[property.property.id] != null &&
                  pinnedProps[property.property.id] ===
                    value.individualProperties[property.property.id]
              "
              @input="
                pinnedPropsUpdate(
                  property.property.id,
                  $event,
                  value.individualProperties[property.property.id]
                )
              "
            ></pin>
          </template>
        </v-text-field>
      </div>
      <p class="mandatoryHint">
        {{ $t('text.starRequired') }}
      </p>
    </div>
  </v-form>
</template>

<script>
import Pin from '@/components/forms/Pin.vue';

export default {
  components: { Pin },
  name: 'DocumentForm',
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    docTypes: {
      type: Array,
      required: true
    },
    pinnedProps: {
      type: Object,
      default: () => {}
    },
    showPins: {
      type: Boolean,
      required: true
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: false
    },
    selectedDocType: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    localizedName: function() {
      let lang = this.$i18n.locale;

      if (lang !== 'de') {
        return 'nameEnglish';
      }

      return 'name';
    }
  },
  mounted() {
    this.setDocType(this.selectedDocType);
    this.formChanged();
  },
  methods: {
    setDocType(docType) {
      this.value.docTypeId = docType.id;
      this.$emit('update:selectedDocType', docType);
    },
    togglePreview() {
      this.$emit('update:showPreview', !this.showPreview);
    },
    formChanged() {
      this.$emit('update:isValid', this.validate());
    },
    pinnedPropsUpdate(key, pinVal, currVal) {
      var updatedProps = Object.assign({}, this.pinnedProps);
      if (pinVal === true) {
        if (!currVal) {
          return;
        }
        updatedProps[key] = currVal;
      } else {
        delete updatedProps[key];
      }

      this.$emit('update:pinnedProps', updatedProps);
    },
    validate() {
      if (!this.value.standardProperties.title) return false;

      if (this.selectedDocType.properties) {
        for (let i = 0; i < this.selectedDocType.properties.length; i++) {
          const prp = this.selectedDocType.properties[i];
          if (!prp.mandatory) continue;

          if (!this.value.individualProperties[prp.property.id]) return false;
        }
      }

      return true;
    },
    getPropLabel(prop) {
      let lang = this.$i18n.locale;
      let suffix = prop.mandatory ? '*' : '';

      if (lang !== 'de') {
        return prop.property.nameEnglish + suffix;
      }

      return prop.property.name + suffix;
    },
    getInputType(propType) {
      if (propType === 'date') {
        return 'date';
      }

      if (propType === 'numeric') {
        return 'number';
      }

      return 'text';
    }
  }
};
</script>

<style lang="scss">
.mandatoryHint {
  font-size: small;
  color: var(--v-primary-base);
}
</style>
