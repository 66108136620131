<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('header.selectTenant') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-skeleton-loader
        v-if="fetchFinished != true"
        class="mx-auto"
        style="min-width: 100%; min-height: 100%"
        type="card"
      ></v-skeleton-loader>
      <div v-else>
        <v-row class="ml-1">
          <v-col
            v-for="(tenantRole, index) in tenantRoles"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            xl="3"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 8 : 2"
                @click="selectTenant(tenantRole)"
              >
                <v-card-title>
                  <div class="md-title">
                    {{ tenantRole.name }}
                  </div>
                </v-card-title>
                <v-card-subtitle>
                  <div class="md-subhead">
                    {{ $t('model.role') + ': ' }}
                    {{ tenantRole.role | roleName }}
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
          <v-col v-if="fetchFinished && tenantRoles.length === 0">
            <v-card elevation="2">
              <v-card-title>
                {{ $t('message.info.noTenantHeader') }}
              </v-card-title>
              <v-card-text>{{ $t('message.info.noTenantText') }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </template>
  </PageContent>
</template>

<script>
import IdentityApi from '@/api/identity.js';
import Notification from '@/service/notification';
import { mutations } from '@/store/store.js';

export default {
  name: 'TenantSelection',
  data() {
    return {
      tenantRoles: [],
      fetchFinished: false
    };
  },
  mounted() {
    this.fetchAvailableTenants();
  },
  methods: {
    fetchAvailableTenants() {
      IdentityApi.getTenantRolesForCurrentUser()
        .then(result => {
          if (result && result.tenants) {
            for (let i = 0; i < result.tenants.length; i++) {
              this.tenantRoles.push(result.tenants[i]);
            }
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchUserTenants'));
        })
        .finally(() => {
          this.fetchFinished = true;
        });
    },
    selectTenant(tenant) {
      mutations.setTenant(tenant);
      this.$router.push('/home');
    }
  }
};
</script>
