import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enVuetify from 'vuetify/es5/locale/en';
import deVuetify from 'vuetify/es5/locale/de';

const de = {
  $vuetify: {
    ...deVuetify,
    customValueExample: 'übersetzung'
  }
};

const en = {
  $vuetify: {
    ...enVuetify,
    customValueExample: 'translation'
  }
};

const messages = {
  de: de,
  en: en
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages
});
