<template>
  <Centered>
    <h2>
      {{ $t('header.profile') }}
    </h2>
    <h3>{{ $t('header.verifyEmail') }}</h3>
    <v-text-field
      v-model="verificationCode"
      :label="$t('model.verificationCode')"
      append-icon="mdi-send"
      @click:append="verifyMail"
    ></v-text-field>
    <div>
      <v-icon>
        mdi-email
      </v-icon>
      <a href="#" @click="sendMailVerificationLink">
        {{ this.$t('button.resendVerification') }}
      </a>
    </div>
    <v-btn
      icon
      class="float-right"
      :title="this.$t('button.cancel')"
      @click="navigateToProfile"
    >
      <v-icon>
        mdi-close-circle
      </v-icon>
    </v-btn>
  </Centered>
</template>

<script>
import AuthService from '@/service/auth';
import Centered from '@/components/layout/Centered.vue';
import Notification from '@/service/notification';

export default {
  name: 'Profile',
  components: {
    Centered
  },
  data() {
    return {
      verificationCode: ''
    };
  },
  methods: {
    navigateToProfile() {
      this.$router.push('/profile');
    },
    sendMailVerificationLink() {
      Notification.inProgress(true);
      AuthService.sendMailVerification()
        .then(response => {
          console.info('sending mail verification success: ', response);
          Notification.success(this.$t('message.success.sendVerificationMail'));
        })
        .catch(err => {
          console.error('error sending mail verification: ', err);
          Notification.error(this.$t('message.error.sendVerificationMail'));
        })
        .finally(() => Notification.inProgress(false));
    },
    verifyMail() {
      if (!this.verificationCode) return;

      Notification.inProgress(true);
      AuthService.verifyMail(this.verificationCode)
        .then(response => {
          console.info('verify mail success: ', response);
          AuthService.updateCurrentUser(true);
          Notification.success(this.$t('message.success.verifyEmail'));
          this.$router.push('/profile');
        })
        .catch(err => {
          console.error('verify mail error: ', err);
          Notification.error(this.$t('message.error.verifyEmail'));
        })
        .finally(() => Notification.inProgress(false));
    }
  }
};
</script>
