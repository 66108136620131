import { client } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  getRating() {
    return client
      .get('/tenant/rating', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  createRating(rating) {
    return client
      .post('/tenant/rating', rating, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  updateRating(rating) {
    return client
      .put('/tenant/rating', rating, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
