<template>
  <PageContent>
    <template v-slot:header>
      <h2>{{ $t('header.profile') }}</h2>
    </template>
    <template v-slot:content>
      <v-form class="ml-2" @submit.prevent="saveChanges">
        <v-text-field
          v-model="currentUser.name"
          :label="$t('model.name')"
        ></v-text-field>
        <v-text-field
          v-model="currentUser.email"
          :label="$t('model.email')"
        ></v-text-field>

        <router-link to="/profile/password">
          {{ $t('navigation.changePassword') }}
        </router-link>

        <div
          v-if="user && !user.attributes.email_verified"
          style="margin-top:20px;"
        >
          <v-icon color="orange">
            mdi-alert
          </v-icon>
          <router-link to="/profile/verification">
            {{ $t('navigation.verification') }}
          </router-link>
        </div>
      </v-form>
    </template>
    <template v-slot:actions>
      <div class="float-right">
        <ResponsiveButton
          color="primary"
          class="ml-2 float-right"
          :text="$t('button.save')"
          icon="mdi-content-save"
          icon-right
          @click="save"
        >
        </ResponsiveButton>
      </div>
    </template>
  </PageContent>
</template>

<script>
import AuthService from '@/service/auth';
import { store } from '@/store/store.js';
import Notification from '@/service/notification';
import IdentityApi from '@/api/identity.js';

export default {
  name: 'Profile',
  data() {
    return {
      editMode: false,
      attributes: {},
      currentUser: {}
    };
  },
  computed: {
    user: function() {
      return store.getUser();
    }
  },
  mounted() {
    this.getCurrentUser();
    this.fillAttributes();
  },
  methods: {
    getCurrentUser() {
      IdentityApi.getCurrentUser()
        .then(r => {
          this.currentUser = r;
        })
        .catch(err => {
          Notification.error(this.$t('message.error.getCurrentUser'));
        });
    },
    fillAttributes() {
      let user = store.getUser();
      if (user) this.attributes = Object.assign({}, user.attributes);
      else this.attributes = {};
    },
    activateEditMode() {
      this.editMode = true;
    },
    disableEditMode() {
      this.editMode = false;
      this.fillAttributes();
    },
    save() {
      console.debug('save changes');
      Notification.inProgress(true);
      IdentityApi.updateCurrentUser(this.currentUser)
        .then(r => {
          Notification.success(this.$t('message.success.editAttributes'));
          AuthService.updateCurrentUser(true).finally(
            () => (this.editMode = false)
          );
        })
        .catch(err => {
          Notification.error(this.$t('message.error.editAttributes'));
        });
    }
  }
};
</script>
