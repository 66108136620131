<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('text.welcome') }}
      </h2>
    </template>
    <template v-slot:content>
      <strong v-if="name">{{ name }}</strong>
      <v-row v-if="!isDevOpsUser">
        <v-col>
          <v-card class="fill-height d-flex flex-column mt-2 mx-2">
            <v-card-title class="py-2">
              Neuste Dokumente
            </v-card-title>
            <v-card-text class="fill-height" style="overflow-y:auto">
              <v-data-table :items="documents" :headers="headers" item-key="id">
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </PageContent>
</template>
<script>
import { store } from '@/store/store.js';
import DocumentApi from '@/api/document.js';
import PermissionApi from '@/api/permission.js';
import Notification from '@/service/notification.js';

export default {
  name: 'Home',
  data() {
    return {
      docTypes: [],
      documents: [],
      headers: [
        {
          text: this.$t('model.title'),
          value: 'title'
        },
        {
          text: this.$t('model.docType'),
          value: 'docTypeName'
        },
        {
          text: this.$t('model.fileName'),
          value: 'fileName'
        },
        {
          text: this.$t('model.lastModified'),
          value: 'lastModified'
        }
      ]
    };
  },
  computed: {
    name: function() {
      let user = store.getUser();
      let tenant = store.getTenant();
      if (user && user.attributes && tenant) {
        return user.attributes.name + ' (' + tenant.name + ')';
      }
      return '';
    },
    isDevOpsUser: function() {
      return Boolean(store.getIsDevOps());
    }
  },
  mounted() {
    if (!this.isDevOpsUser) {
      this.fetchDocTypes();
    }
  },
  methods: {
    fetchDocTypes() {
      PermissionApi.listReadDocTypesForIdentity()
        .then(r => {
          if (r.items && r.items.length > 0) {
            this.docTypes = r.map;
          }
          this.fetchDocuments();
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocTypes'));
        });
    },
    fetchDocuments() {
      DocumentApi.getLatestDocuments()
        .then(r => {
          if (r.items && r.items.length > 0) {
            this.documents = [];
            r.items.forEach(doc => {
              this.documents.push(this.getFlatDocument(doc));
            });
          }
          this.fetchFinished = true;
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocuments'));
        });
    },
    getFlatDocument(doc) {
      let flatDoc = {
        document: doc,
        id: doc.id,
        title: doc.standardProperties.title,
        fileName: doc.standardProperties.file.fileName,
        fileType: doc.standardProperties.file.contentType,
        fileSize: this.$options.filters.formatFileSize(
          doc.standardProperties.file.fileSizeInByte
        ),
        lastModifiedBy: doc.standardProperties.lastModifiedBy,
        lastModified: this.$options.filters.localeDateTime(
          doc.standardProperties.lastModified
        ),
        createdBy: doc.standardProperties.createdBy,
        created: this.$options.filters.localeDateTime(
          doc.standardProperties.created
        ),
        docTypeName: this.docTypes[doc.docTypeId][this.localizedName],
        canEdit: doc.canEdit
      };
      return flatDoc;
    }
  }
};
</script>
