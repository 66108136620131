<template>
  <v-dialog
    :value="showDialog"
    width="unset"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="fireCancelEvent"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" width="90px" @click="fireCancelEvent">
          <v-icon left>
            {{ cancelButtonIcon }}
          </v-icon>
          {{ cancelButtonText }}
        </v-btn>
        <v-btn color="primary" width="90px" @click="fireAcceptEvent">
          <v-icon left>
            {{ acceptButtonIcon }}
          </v-icon>
          {{ acceptButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    acceptButtonText: {
      type: String,
      default: () => this.$t('button.accept')
    },
    acceptButtonIcon: {
      type: String,
      default: 'mdi-check'
    },
    cancelButtonText: {
      type: String,
      default: () => this.$t('button.cancel')
    },
    cancelButtonIcon: {
      type: String,
      default: 'mdi-close-circle'
    }
  },
  methods: {
    fireCancelEvent() {
      this.$emit('update:showDialog', false);
      this.$emit('onCancel');
    },
    fireAcceptEvent() {
      this.$emit('update:showDialog', false);
      this.$emit('onAccept');
    }
  }
};
</script>
