<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('header.user') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-skeleton-loader
        v-if="fetchFinished != true"
        class="mx-auto"
        style="min-width: 100%; min-height: 100%"
        type="table:table-heading, table-thead, table-tbody"
      ></v-skeleton-loader>
      <div v-else>
        <Toolbar show-search @search="onSearch">
          <ToolbarAction
            icon="mdi-plus"
            :title="$t('button.newUser')"
            @click="navigateToCreate"
          />
          <ToolbarAction
            icon="mdi-pencil"
            :title="$t('button.edit')"
            :disabled="!selected"
            @click="navigateToDetails"
          />
          <ToolbarAction
            icon="mdi-delete"
            :title="$t('button.delete')"
            :disabled="!selected"
            @click="showDeletionDialog"
          />
        </Toolbar>

        <v-data-table
          :items="users"
          :headers="headers"
          :search="search"
          item-key="email"
          show-select
          single-select
          @item-selected="onSelect"
        >
        </v-data-table>
      </div>
      <ConfirmationDialog
        :show-dialog.sync="showConfirmation"
        :text="$t('message.confirm.deleteUser')"
        :title="$t('header.delete')"
        :accept-button-text="$t('button.yes')"
        :cancel-button-text="$t('button.no')"
        @onAccept="deleteUser"
      ></ConfirmationDialog>
    </template>
  </PageContent>
</template>

<script>
import IdentityApi from '@/api/identity.js';
import Notification from '@/service/notification.js';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import Utils from '@/service/utils.js';
import Toolbar from '@/components/bars/Toolbar';
import ToolbarAction from '@/components/bars/ToolbarAction';

export default {
  name: 'Users',
  components: {
    ConfirmationDialog,
    Toolbar,
    ToolbarAction
  },
  data() {
    return {
      users: [],
      search: '',
      selected: null,
      showConfirmation: false,
      fetchFinished: false,
      headers: [
        {
          text: this.$t('model.email'),
          value: 'email'
        },
        {
          text: this.$t('model.name'),
          value: 'name'
        },
        {
          text: this.$t('model.role'),
          value: 'role'
        }
      ]
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      IdentityApi.listIdentities()
        .then(r => {
          if (r && r.items) {
            r.items.forEach(item => {
              item.role = this.$options.filters.roleName(item.role);
              this.users.push(item);
            });
          }
          this.fetchFinished = true;
          this.users.sort(Utils.sortByPropertyName('email'));
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchUsers'));
        });
    },
    onSearch(searchText) {
      this.search = searchText;
    },
    navigateToCreate() {
      this.$router.push('/settings/users/new');
    },
    navigateToDetails() {
      if (!this.selected || !this.selected.id) return;
      this.$router.push('/settings/users/' + this.selected.id);
    },
    showDeletionDialog() {
      this.showConfirmation = true;
    },
    onSelect(event) {
      this.selected = event.item;
    },
    removeSelectedUserFromList() {
      if (!this.selected || !this.users) return;

      const id = this.selected.id;

      let newUsers = this.users.filter(function(obj) {
        return obj.id !== id;
      });

      this.users = newUsers;
    },
    deleteUser() {
      IdentityApi.deleteTenantRole(this.selected.id)
        .then(r => {
          this.removeSelectedUserFromList();
          this.selected = null;
          Notification.success(this.$t('message.success.deleteUser'));
        })
        .catch(err => {
          Notification.error(this.$t('message.error.deleteUser'));
        });
    }
  }
};
</script>
