import './plugins/unhandled-errors.js';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/filters.js';
import i18n from '@/i18n/i18n.js';
import vuetify from './plugins/vuetify/vuetify';
import ResponsiveButton from '@/components/buttons/ResponsiveButton';
import PageContent from '@/components/layout/PageContent';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;
Vue.component('ResponsiveButton', ResponsiveButton);
Vue.component('PageContent', PageContent);

// Cookies
Vue.use(VueCookies);
Vue.$cookies.config('7d');

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
