<template>
  <v-list-item :to="to" :link="Boolean(to)">
    <v-list-item-icon v-if="icon">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    icon: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      required: true
    },
    to: {
      type: String,
      default: ''
    }
  }
};
</script>
