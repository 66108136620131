<template>
  <v-app>
    <!-- app bar -->
    <nav-bar></nav-bar>

    <!-- navigation drawer -->
    <side-bar></side-bar>

    <!-- v-main -->
    <v-main>
      <ProgressBar></ProgressBar>
      <v-container class="fill-height">
        <router-view class="fill-height" style="min-width: 100%"></router-view>
      </v-container>
    </v-main>

    <!-- footer -->
    <v-footer app>
      {{ $t('text.app_footer') }}
      <v-spacer></v-spacer>
      <router-link to="/rating" v-if="!isDevOps">
        {{ $t('text.feedback') }}
      </router-link>
    </v-footer>

    <!-- snack bar -->
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
import { store } from '@/store/store';
import NavBar from '@/components/menus/NavBar';
import SideBar from '@/components/menus/SideBar';
import ProgressBar from '@/components/bars/ProgressBar';
import Snackbar from '@/components/bars/Snackbar';

export default {
  name: 'App',
  components: {
    NavBar,
    SideBar,
    ProgressBar,
    Snackbar
  },
  computed: {
    user: function() {
      return store.getUser();
    },
    tenant: function() {
      return store.getTenant();
    },
    isDevOps: function() {
      return store.getIsDevOps();
    },
    breakpoint: function() {
      return this.$vuetify.breakpoint;
    }
  },
  created() {
    if (process.env.VUE_APP_ENV_APPENDIX)
      document.title = 'res.share - ' + process.env.VUE_APP_ENV_APPENDIX;
    else document.title = 'res.share';
  },
  methods: {
    navigateToFeedback() {
      this.$router.push('/rating');
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto Condensed', sans-serif;
}

html {
  overflow-y: auto;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
}

#app {
  font-family: 'Roboto Condensed', sans-serif;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
}
</style>
