<template>
  <v-progress-linear
    v-if="inProgress"
    indeterminate
    color="primary"
  ></v-progress-linear>
</template>

<script>
import { EventBus } from '@/service/event-bus.js';

export default {
  name: 'ProgressBar',
  data() {
    return {
      inProgress: false
    };
  },
  mounted() {
    EventBus.$on('inProgress', inProgress => {
      console.debug('inProgress: ', inProgress);
      this.inProgress = inProgress;
    });
  }
};
</script>
