import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en.json';
import de from './de.json';

const messages = {
  de: de,
  en: en
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages
});
