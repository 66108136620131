<template>
  <Centered>
    <h2>
      {{ this.$t('header.register') }}
    </h2>
    <v-text-field
      v-model="registration.name"
      :label="this.$t('model.name') + '*'"
    ></v-text-field>
    <v-text-field
      v-model="registration.country"
      :label="this.$t('model.country') + '*'"
    ></v-text-field>
    <v-text-field
      v-model="registration.city"
      :label="this.$t('model.city') + '*'"
    ></v-text-field>
    <v-text-field
      v-model="registration.postCode"
      :label="this.$t('model.postCode')"
    ></v-text-field>
    <v-text-field
      v-model="registration.street"
      :label="this.$t('model.street')"
    ></v-text-field>
    <v-text-field
      v-model="registration.phone"
      :label="this.$t('model.phone')"
    ></v-text-field>
    <v-text-field
      v-model="registration.contactEmail"
      :label="this.$t('model.contactEmail')"
    ></v-text-field>
    <v-text-field
      v-model="registration.adminEmail"
      :label="this.$t('model.adminEmail') + '*'"
    ></v-text-field>
    <v-text-field
      v-model="registration.license"
      :label="this.$t('model.license')"
    ></v-text-field>
    <v-btn class="float-right" :disabled="!canSave" @click="save">
      <v-icon :disabled="disabled">
        mdi-content-save
      </v-icon>
      {{ this.$t('button.createTenant') }}
    </v-btn>
  </Centered>
</template>

<script>
import Notification from '@/service/notification';
import Centered from '@/components/layout/Centered';
import TenantApi from '@/api/tenant.js';

export default {
  name: 'TenantCreation',
  components: {
    Centered
  },
  data() {
    return {
      registration: {},
      disabled: false
    };
  },
  computed: {
    canSave: function() {
      return (
        this.registration &&
        this.registration.name &&
        this.registration.country &&
        this.registration.city &&
        this.registration.adminEmail
      );
    }
  },
  methods: {
    save() {
      if (!this.canSave) return;

      this.disabled = true;

      TenantApi.registerTenant(this.registration)
        .then(response => {
          this.tenantUrl = response.url;
          Notification.success(this.$t('message.success.postRegistration'));
          this.$router.push('/tenants');
        })
        .catch(err => {
          Notification.error(this.$t('message.error.postRegistration'));
        })
        .finally(() => {
          this.disabled = false;
        });
    }
  }
};
</script>
