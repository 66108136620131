<template>
  <v-toolbar dense elevation="0">
    <slot />

    <v-spacer v-if="showSearch"></v-spacer>

    <v-text-field
      v-if="showSearch"
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('model.search')"
      single-line
      hide-details
    ></v-text-field>
  </v-toolbar>
</template>

<script>
export default {
  name: 'Toolbar',
  props: {
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: ''
    };
  },
  watch: {
    search: function(searchValue) {
      this.$emit('search', searchValue);
    }
  }
};
</script>
