<template>
  <PageContent header-height="115px">
    <template v-slot:header>
      <h2 v-if="id === 'new'">
        {{ $t('header.groupDetailsCreate') }}: {{ group.name }}
      </h2>
      <h2 v-else>
        {{ $t('header.groupDetailsEdit') }}:
        {{ group.name }}
      </h2>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ $t('header.general') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            {{ $t('header.users') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            {{ $t('header.documentPermissions') }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-divider></v-divider>
    </template>
    <template v-slot:content>
      <v-form :disabled="isLoading || isError">
        <div v-if="step === 1" id="page-1" class="px-2">
          <v-text-field
            v-model="group.name"
            :label="$t('model.name') + '*'"
          ></v-text-field>
          <v-text-field
            v-model="group.nameEnglish"
            :label="$t('model.nameEnglish')"
          ></v-text-field>

          <h4 class="pt-2">
            {{ $t('header.attributeRestriction') }}
          </h4>

          <v-row>
            <v-col cols="5">
              <v-select
                v-model="group.attributeId"
                :label="$t('model.attribute')"
                :items="availableAttributes"
                :item-text="localizedName"
                item-value="id"
                clearable
                @click:clear="clearCustomValue"
              ></v-select>
              <v-text-field
                v-if="group.attributeId === 'custom'"
                v-model="group.customValue"
                :label="$t('model.customValue')"
              >
              </v-text-field>
            </v-col>
            <v-col class="align-self-center" justify="center" align="center">
              <v-icon>mdi-equal</v-icon>
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="group.docPropertyId"
                :label="$t('model.property')"
                :items="availableProperties"
                :item-text="localizedName"
                item-value="id"
                clearable
                @click:clear="delete group.docPropertyId"
              ></v-select>
            </v-col>
          </v-row>

          <v-list>
            <v-list-item
              v-for="(attribute, index) in selectedAttributes"
              :key="index"
            >
              <v-list-item-content>
                <strong>{{ attribute.key[localizedName] }}:</strong>
                {{ attribute.value }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  :label="$t('button.delete')"
                  @click="removeAttribute(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="step === 2" id="page-1" class="px-2">
          <v-autocomplete
            v-model="user"
            :items="availableIdentities"
            :placeholder="$t('model.selectUser')"
            :loading="loadingIdentities"
            :disabled="loadingIdentities"
            item-text="displayName"
            item-value="id"
            auto-select-first
            @change="onIdentitySelected"
          ></v-autocomplete>

          <v-list>
            <v-list-item
              v-for="(identity, index) in selectedIdentities"
              :key="index"
            >
              <v-list-item-content>
                {{ identity.displayName }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  :label="$t('button.delete')"
                  @click="removeIdentity(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="step === 3">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('model.name') }}
                  </th>
                  <th class="text-left">
                    {{ $t('model.read') }}
                  </th>
                  <th class="text-left">
                    {{ $t('model.write') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(operation, key) in group.documentOperations"
                  :key="key"
                >
                  <td>
                    {{ operation[localizedName] }}
                    <v-tooltip
                      v-if="
                        (operation.canRead || operation.canWrite) &&
                          Boolean(group.docPropertyId) &&
                          operation.properties &&
                          operation.properties.indexOf(group.docPropertyId) < 0
                      "
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="orange" v-bind="attrs" v-on="on">
                          > mdi-alert
                        </v-icon>
                      </template>
                      <span>{{
                        $t('message.warn.missingPropertyInGroup')
                      }}</span>
                    </v-tooltip>
                  </td>

                  <td>
                    <v-checkbox v-model="operation.canRead"></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox v-model="operation.canWrite"></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-form>
    </template>
    <template v-slot:actions>
      <div class="pt-2">
        <ResponsiveButton
          icon="mdi-close-circle"
          icon-left
          :text="$t('button.cancel')"
          @click="navigateToList"
        >
        </ResponsiveButton>
        <div class="float-right">
          <ResponsiveButton
            v-if="step !== 1"
            icon=" mdi-arrow-left"
            :text="$t('button.back')"
            icon-left
            @click="previousPage"
          >
          </ResponsiveButton>
          <ResponsiveButton
            v-if="step === 3"
            color="primary"
            class="ml-2"
            :text="$t('button.save')"
            icon="mdi-content-save"
            :disabled="!canSave"
            icon-right
            @click="save"
          >
          </ResponsiveButton>
          <ResponsiveButton
            v-else
            :text="$t('button.next')"
            icon-right
            icon="mdi-arrow-right"
            color="primary"
            class="ml-2"
            @click="nextPage"
          >
          </ResponsiveButton>
        </div>
      </div>
    </template>
  </PageContent>
</template>

<script>
import DocPropertyApi from '@/api/docProperty.js';
import DocTypeApi from '@/api/docType.js';
import GroupApi from '@/api/group.js';
import IdentityApi from '@/api/identity.js';
import AttributeApi from '@/api/attribute.js';
import Notification from '@/service/notification.js';
import Utils from '@/service/utils.js';

export default {
  name: 'GroupDetails',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      isError: false,
      step: 1,
      loadingAttributes: false,
      loadingIdentities: false,
      loadingProperties: false,
      loadingDocTypes: false,
      loadingGroup: true,
      group: {},
      selectedAttributes: [],
      availableAttributes: [],
      availableIdentities: [],
      selectedIdentities: [],
      availableProperties: [],
      availableDocTypes: [],
      attributeKey: '',
      attributeValue: '',
      user: undefined
    };
  },
  computed: {
    isLoading: function() {
      return this.loadingAttributes || this.loadingIdentities;
    },
    canSave: function() {
      return (
        !this.isLoading &&
        !this.isError &&
        Boolean(this.group) &&
        Boolean(this.group.name)
      );
    },
    localizedName: function() {
      let lang = this.$i18n.locale;

      if (lang !== 'de') {
        return 'nameEnglish';
      }

      return 'name';
    }
  },
  mounted() {
    this.fetchGroup();
  },
  methods: {
    clearCustomValue() {
      this.group.customValue = null;
    },
    previousPage() {
      if (this.step === 1) return;
      this.step -= 1;
    },
    nextPage() {
      if (this.step === 3) return;
      this.step += 1;
    },
    fetchGroup() {
      if (this.id === 'new') {
        this.loadingGroup = false;
        this.fetchAttributes();
        this.fetchProperties();
        this.fetchIdentities();
        this.fetchDocTypes();
        return;
      }

      GroupApi.getGroup(this.id)
        .then(r => {
          if (r) {
            console.debug('response: ', r);
            this.group = r;
            this.fetchAttributes();
            this.fetchProperties();
            this.fetchIdentities();
            this.fetchDocTypes();
          }
        })
        .catch(err => {
          this.isError = true;
          Notification.error(this.$t('message.error.fetchGroup'));
        })
        .finally(() => (this.loadingGroup = false));
    },
    fetchProperties() {
      this.loadingProperties = true;
      DocPropertyApi.getProperties()
        .then(r => {
          if (r && r.items) {
            this.availableProperties = r.items;
            this.availableProperties.sort(
              Utils.sortByPropertyName(this.localizedName)
            );
          }
        })
        .catch(err => {
          this.isError = true;
          Notification.error(this.$t('message.error.fetchDocProperties'));
        })
        .finally(() => (this.loadingProperties = false));
    },
    fetchDocTypes() {
      this.loadingDocTypes = true;
      DocTypeApi.getDocTypes()
        .then(r => {
          if (!r.items || r.items.length === 0) {
            return;
          }

          if (!this.group.documentOperations) {
            this.$set(this.group, 'documentOperations', {});
          }

          if (this.id === 'new') {
            r.items.forEach(docType => {
              let dct = {
                canRead: false,
                canWrite: false,
                name: docType.name,
                nameEnglish: docType.nameEnglish
              };

              if (docType.properties) {
                dct.properties = [];
                docType.properties.forEach(prp => {
                  dct.properties.push(prp.property.id);
                });
              }

              this.$set(this.group.documentOperations, docType.id, dct);
            });
            return;
          }

          r.items.forEach(docType => {
            if (this.group.documentOperations[docType.id]) {
              this.group.documentOperations[docType.id].name = docType.name;
              this.group.documentOperations[docType.id].nameEnglish =
                docType.nameEnglish;
            } else {
              let dct = {
                canRead: false,
                canWrite: false,
                name: docType.name,
                nameEnglish: docType.nameEnglish
              };
              this.$set(this.group.documentOperations, docType.id, dct);
            }

            if (docType.properties) {
              this.group.documentOperations[docType.id].properties = [];
              docType.properties.forEach(prp => {
                this.group.documentOperations[docType.id].properties.push(
                  prp.property.id
                );
              });
            }
          });
        })
        .catch(err => {
          console.error('error: ', err);
          this.isError = true;
          Notification.error(this.$t('message.error.fetchDocTypes'));
        })
        .finally(() => (this.loadingDocTypes = false));
    },
    fetchAttributes() {
      this.loadingAttributes = true;
      AttributeApi.listAttributes(true)
        .then(r => {
          if (r && r.items) {
            this.availableAttributes = r.items;
            this.availableAttributes.sort(
              Utils.sortByPropertyName(this.localizedName)
            );
          }

          let custom = {
            id: 'custom',
            name: this.$t('model.customValue', 'de'),
            nameEnglish: this.$t('model.customValue', 'en')
          };

          this.availableAttributes.push(custom);
        })
        .catch(err => {
          this.isError = true;
          Notification.error(this.$t('message.error.fetchAttributes'));
        })
        .finally(() => (this.loadingAttributes = false));
    },
    selectAttribute() {
      this.addAttribute(this.attributeKey, this.attributeValue);
      this.attributeKey = '';
      this.attributeValue = '';
    },
    addAttribute(attributeKey, attributeValue) {
      console.debug('addAttribute - key: ', attributeKey);
      console.debug('addAttribute - value: ', attributeValue);

      if (attributeKey && attributeValue) {
        var index = this.availableAttributes
          .map(e => e.id)
          .indexOf(attributeKey);

        if (index < 0) return;

        let attr = {
          key: this.availableAttributes[index],
          value: attributeValue
        };

        this.selectedAttributes.push(attr);
        this.availableAttributes.splice(index, 1);

        if (!this.group.attributes) this.group.attributes = {};
        this.group.attributes[attributeKey] = attributeValue;
      }
    },
    removeAttribute(index) {
      console.debug('removeAttribute: ', index);

      var attrToRemove = this.selectedAttributes[index].key;
      console.debug('atrrToRemove: ', attrToRemove);

      this.availableAttributes.push(attrToRemove);
      this.availableAttributes.sort(
        Utils.sortByPropertyName(this.localizedName)
      );

      this.selectedAttributes.splice(index, 1);

      delete this.group.attributes[attrToRemove.id];
    },
    fetchIdentities() {
      this.loadingIdentities = true;
      IdentityApi.listIdentities(true)
        .then(r => {
          if (r && r.items && r.items.length > 0) {
            this.availableIdentities = r.items;
            this.availableIdentities.sort(Utils.sortByPropertyName('name'));

            if (this.group && this.group.identities) {
              var idsToRefresh = Object.assign([], this.group.identities);
              this.group.identities = [];

              this.$nextTick(() => {
                idsToRefresh.forEach(id => this.addIdentity(id));
              });
            }
          }
        })
        .catch(err => {
          this.isError = true;
          Notification.error(this.$t('message.error.fetchUsers'));
        })
        .finally(() => (this.loadingIdentities = false));
    },
    removeIdentity(index) {
      console.debug('removeIdentity: ', index);

      var selected = this.selectedIdentities[index];

      this.availableIdentities.push(selected);
      this.availableIdentities.sort(Utils.sortByPropertyName('displayName'));

      this.selectedIdentities.splice(index, 1);

      this.group.identities.splice(
        this.group.identities.indexOf(selected.id),
        1
      );
    },
    onIdentitySelected() {
      this.$nextTick(() => {
        this.addIdentity(this.user);
        this.user = undefined;
      });
    },
    addIdentity(id) {
      console.debug('addIdentity: ', id);

      if (id) {
        var index = this.availableIdentities.map(e => e.id).indexOf(id);

        console.debug('user index: ', index);

        if (index < 0) return;

        var selected = this.availableIdentities[index];

        this.selectedIdentities.push(selected);
        this.availableIdentities.splice(index, 1);

        if (!this.group.identities) this.group.identities = [];
        this.group.identities.push(id);
      }
    },
    save() {
      if (!this.canSave) return;

      if (!this.group.id || this.group.id === 'new') {
        GroupApi.createGroup(this.group)
          .then(r => {
            this.group = r;
            Notification.success(this.$t('message.success.createGroup'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.createGroup'));
          });
      } else {
        GroupApi.updateGroup(this.group)
          .then(r => {
            this.group = r;
            Notification.success(this.$t('message.success.editGroup'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.editGroup'));
          });
      }
    },
    navigateToList() {
      this.$router.push('/settings/groups');
    }
  }
};
</script>
