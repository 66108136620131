<template>
  <PageContent header-height="115px">
    <template v-slot:header>
      <h2 v-if="id === 'new'">
        {{ $t('header.docTypeDetailsCreate') }}: {{ documentType.name }}
      </h2>
      <h2 v-else>
        {{ $t('header.docTypeDetailsUpdate') }}: {{ documentType.name }}
      </h2>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ $t('header.general') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            {{ $t('header.docProperties') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            {{ $t('header.mandatoryFields') }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
    <template v-slot:content>
      <div v-if="step === 1" id="page-1" class="px-2">
        <v-text-field
          v-model="documentType.name"
          :label="$t('model.name') + '*'"
        ></v-text-field>
        <v-text-field
          v-model="documentType.nameEnglish"
          :label="$t('model.nameEnglish')"
        ></v-text-field>
        <v-textarea
          v-model="documentType.description"
          name="input-7-1"
          :label="$t('model.description')"
        ></v-textarea>
        <v-textarea
          v-model="documentType.descriptionEnglish"
          name="input-7-1"
          :label="$t('model.descriptionEnglish')"
        ></v-textarea>
      </div>
      <div v-if="step === 2" id="page-2" class="px-2">
        <v-list flat>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(prop, i) in properties"
              :key="i"
              @click="select(prop)"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ prop.type | propertyTypeIcon }}
                </v-icon>
              </v-list-item-icon>

              {{ prop.name }}
              <v-spacer></v-spacer>
              <v-icon v-if="prop.isSelected" color="primary">
                mdi-check-bold
              </v-icon>
              <v-icon v-else color="secondary"> mdi-check-outline </v-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div v-if="step === 3" id="page-3" class="px-2">
        <v-list v-if="selectedProperties && selectedProperties.length > 0" flat>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(selected, i) in selectedProperties"
              :key="i"
              @click="mandatory(selected)"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ selected.type | propertyTypeIcon }}
                </v-icon>
              </v-list-item-icon>
              {{ selected.name }}
              <v-spacer></v-spacer>
              <v-icon v-if="selected.isMandatory" color="primary">
                mdi-key
              </v-icon>
              <v-icon v-else color="secondary"> mdi-key-outline </v-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <span v-else>
          {{ $t('text.noPropertiesSelected') }}
        </span>
      </div>
    </template>
    <template v-slot:actions>
      <div class="pt-2">
        <ResponsiveButton
          icon="mdi-close-circle"
          icon-left
          :text="$t('button.cancel')"
          @click="navigateToList"
        >
        </ResponsiveButton>
        <div class="float-right">
          <ResponsiveButton
            v-if="step !== 1"
            icon=" mdi-arrow-left"
            :text="$t('button.back')"
            icon-left
            @click="previousPage"
          >
          </ResponsiveButton>
          <ResponsiveButton
            v-if="step === 3"
            color="primary"
            class="ml-2"
            :text="$t('button.save')"
            icon="mdi-content-save"
            icon-right
            :disabled="!canSave"
            @click="save"
          >
          </ResponsiveButton>
          <ResponsiveButton
            v-else
            :text="$t('button.next')"
            icon-right
            icon="mdi-arrow-right"
            color="primary"
            class="ml-2"
            @click="nextPage"
          >
          </ResponsiveButton>
        </div>
      </div>
    </template>
  </PageContent>
</template>

<script>
import DocPropertyApi from '@/api/docProperty.js';
import DocTypesApi from '@/api/docType.js';
import Notification from '@/service/notification.js';

export default {
  name: 'DocTypeDetails',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      documentType: {},
      properties: []
    };
  },
  computed: {
    canSave: function() {
      return this.documentType && this.documentType.name;
    },
    selectedProperties: function() {
      let selectedProps = this.properties.filter(function(obj) {
        return obj.isSelected === true;
      });
      return selectedProps;
    }
  },
  mounted() {
    if (this.id === 'new') {
      this.fetchProperties();
    } else {
      this.fetchDocType();
    }
  },
  methods: {
    previousPage() {
      if (this.step === 1) return;
      this.step -= 1;
    },
    nextPage() {
      if (this.step === 3) return;
      this.step += 1;
    },
    navigateToList() {
      this.$router.push('/settings/doctypes');
    },
    select(prop) {
      this.$set(prop, 'isSelected', !prop.isSelected);
    },
    mandatory(prop) {
      this.$set(prop, 'isMandatory', !prop.isMandatory);
    },
    save() {
      if (this.id === 'new') this.postDocType();
      else this.updateDocType();
    },
    postDocType() {
      if (!this.canSave) return;

      DocTypesApi.addDocType(this.getDocTypeObject())
        .then(r => {
          Notification.success(this.$t('message.success.createDocType'));
          this.navigateToList();
        })
        .catch(err =>
          Notification.error(this.$t('message.error.createDocType'))
        );
    },
    updateDocType() {
      if (!this.canSave) return;

      DocTypesApi.putDocType(this.getDocTypeObject())
        .then(r => {
          Notification.success(this.$t('message.success.editDocType'));
          this.navigateToList();
        })
        .catch(err => Notification.error(this.$t('message.error.editDocType')));
    },
    getDocTypeObject() {
      let newType = this.documentType;
      newType.properties = [];

      this.selectedProperties.forEach(prop => {
        let assignedProp = {
          property: prop,
          mandatory: prop.isMandatory
        };
        newType.properties.push(assignedProp);
      });
      return newType;
    },
    fetchDocType() {
      DocPropertyApi.getProperties()
        .then(r => {
          if (r.items && r.items.length > 0) {
            this.properties = r.items;
            this.properties.sort((a, b) => a.name.localeCompare(b.name));

            DocTypesApi.getDocType(this.id)
              .then(r => {
                console.log('r.prop: ', r.properties);
                if (r && r.properties) {
                  r.properties.forEach(prop => {
                    for (let i = 0; i < this.properties.length; i++) {
                      if (this.properties[i].id === prop.property.id) {
                        this.$set(this.properties[i], 'isSelected', true);
                        this.$set(
                          this.properties[i],
                          'isMandatory',
                          prop.mandatory
                        );
                        break;
                      }
                    }
                  });
                  this.documentType = r;
                }
              })
              .catch(err =>
                Notification.error(this.$t('message.error.getDocType'))
              );
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocProperties'));
        });
    },
    fetchProperties() {
      DocPropertyApi.getProperties()
        .then(r => {
          if (r.items && r.items.length > 0) {
            this.properties = r.items;
            this.properties.sort((a, b) => a.name.localeCompare(b.name));
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocProperties'));
        });
    }
  }
};
</script>
