import { client } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  getProperties() {
    return client
      .get('/document/property', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getProperty(id) {
    return client
      .get('/document/property/' + id, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  addProperty(property) {
    return client
      .post('/document/property', property, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  deleteProperty(id) {
    return client
      .delete('/document/property/' + id, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
