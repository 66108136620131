var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"px-2",attrs:{"disabled":_vm.disabled}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"value":_vm.selectedDocType,"items":_vm.docTypes,"item-text":_vm.localizedName,"return-object":"","label":_vm.$t('model.selectDocType') + '*'},on:{"change":_vm.setDocType}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"value":_vm.value.standardProperties.file.fileName,"label":_vm.$t('model.fileName'),"readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","color":_vm.showPreview ? 'primary' : ''},on:{"click":_vm.togglePreview}},[_c('v-icon',[_vm._v(" mdi-view-split-vertical ")])],1)]},proxy:true}])})],1)],1),(Boolean(_vm.selectedDocType) && _vm.selectedDocType.id)?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('model.properties')))]),_c('v-text-field',{attrs:{"label":_vm.$t('model.title') + '*',"required":""},on:{"input":_vm.formChanged},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPins)?_c('pin',{attrs:{"value":_vm.pinnedProps.title != null &&
              _vm.pinnedProps.title === _vm.value.standardProperties.title},on:{"input":function($event){return _vm.pinnedPropsUpdate('title', $event, _vm.value.standardProperties.title)}}}):_vm._e()]},proxy:true}],null,false,1033468938),model:{value:(_vm.value.standardProperties.title),callback:function ($$v) {_vm.$set(_vm.value.standardProperties, "title", $$v)},expression:"value.standardProperties.title"}}),_vm._l((_vm.selectedDocType.properties),function(property,index){return _c('div',{key:index},[(property.property.type === 'boolean')?_c('v-radio-group',{attrs:{"row":"","label":_vm.getPropLabel(property)},on:{"input":_vm.formChanged},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPins)?_c('pin',{attrs:{"value":_vm.pinnedProps[property.property.id] != null &&
                _vm.pinnedProps[property.property.id] ===
                  _vm.value.individualProperties[property.property.id]},on:{"input":function($event){return _vm.pinnedPropsUpdate(
                property.property.id,
                $event,
                _vm.value.individualProperties[property.property.id]
              )}}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.value.individualProperties[property.property.id]),callback:function ($$v) {_vm.$set(_vm.value.individualProperties, property.property.id, $$v)},expression:"value.individualProperties[property.property.id]"}},[_c('v-radio',{attrs:{"label":_vm.$t('model.yes'),"value":"true"}}),_c('v-radio',{attrs:{"label":_vm.$t('model.no'),"value":"false"}})],1):(
          property.restrictionValues &&
            property.restrictionValues.length === 1
        )?_c('v-text-field',{attrs:{"run":(_vm.value.individualProperties[property.property.id] =
            property.restrictionValues[0]),"label":_vm.getPropLabel(property),"type":_vm.getInputType(property.property.type),"readonly":true},on:{"input":_vm.formChanged},model:{value:(_vm.value.individualProperties[property.property.id]),callback:function ($$v) {_vm.$set(_vm.value.individualProperties, property.property.id, $$v)},expression:"value.individualProperties[property.property.id]"}}):(
          property.restrictionValues && property.restrictionValues.length > 1
        )?_c('v-select',{attrs:{"items":property.restrictionValues,"label":_vm.getPropLabel(property),"type":"text"},on:{"input":_vm.formChanged},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPins)?_c('pin',{attrs:{"value":_vm.pinnedProps[property.property.id] != null &&
                _vm.pinnedProps[property.property.id] ===
                  _vm.value.individualProperties[property.property.id]},on:{"input":function($event){return _vm.pinnedPropsUpdate(
                property.property.id,
                $event,
                _vm.value.individualProperties[property.property.id]
              )}}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.value.individualProperties[property.property.id]),callback:function ($$v) {_vm.$set(_vm.value.individualProperties, property.property.id, $$v)},expression:"value.individualProperties[property.property.id]"}},[_vm._v(" "+_vm._s(_vm._f("propertyTypeIcon")(property.property.type))+" ")]):_c('v-text-field',{attrs:{"label":_vm.getPropLabel(property),"type":_vm.getInputType(property.property.type)},on:{"input":_vm.formChanged},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPins)?_c('pin',{attrs:{"value":_vm.pinnedProps[property.property.id] != null &&
                _vm.pinnedProps[property.property.id] ===
                  _vm.value.individualProperties[property.property.id]},on:{"input":function($event){return _vm.pinnedPropsUpdate(
                property.property.id,
                $event,
                _vm.value.individualProperties[property.property.id]
              )}}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.value.individualProperties[property.property.id]),callback:function ($$v) {_vm.$set(_vm.value.individualProperties, property.property.id, $$v)},expression:"value.individualProperties[property.property.id]"}})],1)}),_c('p',{staticClass:"mandatoryHint"},[_vm._v(" "+_vm._s(_vm.$t('text.starRequired'))+" ")])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }