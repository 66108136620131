<template>
  <v-dialog
    :value="showDialog"
    max-width="85%"
    scrollable
    @click:outside="closeDialog"
  >
    <v-card style="height:85vh">
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-toolbar-title>
            {{ fileTitle ? fileTitle : $t('model.document') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text style="overflow:auto" class="pa-0">
        <document-viewer :mime-type="fileType" :url="fileUrl"></document-viewer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DocumentViewer from '@/components/viewer/DocumentViewer.vue';

export default {
  name: 'DocumentViewerDialog',
  components: { DocumentViewer },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    fileTitle: {
      type: String,
      default: ''
    },
    fileUrl: {
      type: String,
      required: true
    },
    fileType: {
      type: String,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:showDialog', false);
    }
  }
};
</script>
