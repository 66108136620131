<template>
  <div>{{ $t('text.logoutInProgress') }}</div>
</template>

<script>
import AuthService from '@/service/auth';
import { mutations } from '@/store/store';
import Notification from '@/service/notification';

export default {
  name: 'Logout',
  mounted() {
    this.signOut();
  },
  methods: {
    signOut() {
      AuthService.logOutUser()
        .then(() => {
          console.info('logout successful');
          mutations.setTenant(null);
          mutations.setUser(null);
          this.$router.push('/welcome');
        })
        .catch(error => {
          console.error('error on logout: ', error);
          Notification.error(this.$t('message.error.logout'));
          this.$router.push('/welcome');
        });
    }
  }
};
</script>
