import Vue from 'vue';

Vue.config.errorHandler = function(err, vm, info) {
  console.warn('An unhandled error occured in Vue: ', err);
};

window.onerror = function(msg, url, line, col, error) {
  console.warn('An unhandled error occured on window object: ', msg);
};

window.addEventListener('unhandledrejection', function(event) {
  //event.promise contains the promise object
  //event.reason contains the reason for the rejection
  console.warn('A promise was unhandled: ', event);
  console.debug('unhandled promise: ', event.promise);
  console.debug('unhandled reason: ', event.reason);
});
