import { client } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  listGroups() {
    return client
      .get('/document/group', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  createGroup(group) {
    return client
      .post('/document/group', group, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getGroup(id) {
    return client
      .get('/document/group/' + id, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  updateGroup(group) {
    return client
      .put('/document/group/' + group.id, group, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  deleteGroup(id) {
    return client
      .delete('/document/group/' + id, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
