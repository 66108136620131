<template>
  <PageContent>
    <template v-slot:header>
      <h2>
        {{ $t('header.rating') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-row class="mt-2 mx-2">
        <v-col cols="4">
          <v-card>
            <v-card-title> {{ $t('header.usability') }}</v-card-title>
            <v-card-subtitle>
              {{ $t('header.sub.usability') }}
            </v-card-subtitle>
            <v-card-text>
              <v-rating
                v-model="rating.usability"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half"
                hover
                length="5"
                size="30"
                background-color="grey lighten-2"
              ></v-rating>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title>{{ $t('header.design') }}</v-card-title>
            <v-card-subtitle>
              {{ $t('header.sub.design') }}
            </v-card-subtitle>
            <v-card-text>
              <v-rating
                v-model="rating.design"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half"
                hover
                length="5"
                size="30"
                background-color="grey lighten-2"
              ></v-rating>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title>{{ $t('header.functionRange') }}</v-card-title>
            <v-card-subtitle>
              {{ $t('header.sub.functionRange') }}
            </v-card-subtitle>
            <v-card-text>
              <v-rating
                v-model="rating.function"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half"
                hover
                length="5"
                size="30"
                background-color="grey lighten-2"
              ></v-rating>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col>
          <v-card>
            <v-card-title>{{ $t('header.others') }}</v-card-title>
            <v-card-subtitle>
              {{ $t('header.sub.others') }}
            </v-card-subtitle>
            <v-card-text>
              <v-textarea v-model="rating.text" name="input-7-1"></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="float-right">
        <ResponsiveButton
          icon="mdi-close-circle"
          icon-left
          :text="$t('button.cancel')"
          @click="navigateToHome"
        >
        </ResponsiveButton>
        <ResponsiveButton
          color="primary"
          class="ml-2 float-right"
          :text="$t('button.save')"
          icon="mdi-content-save"
          icon-right
          @click="save"
        >
        </ResponsiveButton>
      </div>
    </template>
  </PageContent>
</template>
<script>
import IdentityApi from '@/api/identity.js';
import RatingApi from '@/api/rating.js';
import Notification from '@/service/notification';

export default {
  name: 'UserRating',
  data: function() {
    return {
      currentUser: {},
      rating: {
        id: '',
        usability: 0,
        design: 0,
        function: 0,
        test: ''
      }
    };
  },
  mounted() {
    this.getCurrentUser();
  },
  methods: {
    getCurrentUser() {
      IdentityApi.getCurrentUser()
        .then(r => {
          this.currentUser = r;
          this.getRating();
        })
        .catch(err => {
          Notification.error(this.$t('message.error.getCurrentUser'));
        });
    },
    save() {
      if (this.rating.id === '') {
        RatingApi.createRating(this.rating)
          .then(r => {
            Notification.success(this.$t('message.success.createRating'));
            this.navigateToHome();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.createRating'));
          });
      } else {
        RatingApi.updateRating(this.rating)
          .then(r => {
            Notification.success(this.$t('message.success.updateRating'));
            this.navigateToHome();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.updateRating'));
          });
      }
    },
    navigateToHome() {
      this.$router.push('home');
    },
    getRating() {
      RatingApi.getRating(this.currentUser)
        .then(r => {
          if (r) {
            this.rating = r;
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.getRating'));
        });
    }
  }
};
</script>
