import { client } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  getDocTypes() {
    return client
      .get('/document/type', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getDocType(id) {
    return client
      .get('/document/type/' + id, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  addDocType(type) {
    return client
      .post('/document/type', type, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  putDocType(type) {
    return client
      .put('/document/type/' + type.id, type, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  deleteDocType(id) {
    return client
      .delete('/document/type/' + id, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
