<template>
  <Centered>
    <ChangePassword
      v-if="newPasswordRequired"
      @changePassword="changePassword"
    ></ChangePassword>
    <LoginForm v-else @login="login"></LoginForm>
  </Centered>
</template>
<script>
import LoginForm from '@/components/login/LoginForm.vue';
import ChangePassword from '@/components/login/ChangePassword.vue';
import AuthService from '@/service/auth';
import { store, mutations } from '@/store/store';
import Notification from '@/service/notification.js';
import Centered from '@/components/layout/Centered';

export default {
  name: 'Login',
  components: {
    LoginForm,
    ChangePassword,
    Centered
  },
  data() {
    return {
      cognitoUser: null,
      newPasswordRequired: false
    };
  },
  computed: {
    store: function() {
      return store;
    }
  },
  methods: {
    login(email, password) {
      if (!email || !email.trim() || !password) {
        return;
      }

      Notification.inProgress(true);
      AuthService.loginUser(email.trim(), password)
        .then(user => {
          console.info('log in successful: ', user);

          if (user.signInUserSession) {
            mutations.setUser(user);
            this.$router.push('/tenant/selection');
            return;
          }

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.oldPassword = password;
            this.cognitoUser = user;
            this.newPasswordRequired = true;
          }
        })
        .catch(error => {
          Notification.error(this.$t('message.error.login'));
          console.error('error on log in: ', error);
        })
        .finally(() => Notification.inProgress(false));
    },
    changePassword(newPassword) {
      Notification.inProgress(true);
      AuthService.completePassword(this.cognitoUser, newPassword)
        .then(user => {
          console.info('change password successful: ', user);
          Notification.success(this.$t('message.success.changePassword'));

          if (user.signInUserSession) {
            mutations.setUser(user);
            this.$router.push('/tenant/selection');
            return;
          }
        })
        .catch(error => {
          console.error('error on change password: ', error);
          Notification.error(this.$t('message.error.changePassword'));
        })
        .finally(() => Notification.inProgress(false));
    }
  }
};
</script>
