<template>
  <PageContent
    :show-side-content="showSideContent"
    hide-actions
    :size-side-content="4"
  >
    <template v-slot:header>
      <h2>
        {{ $t('header.documents') }}
      </h2>
    </template>
    <template v-slot:content>
      <v-skeleton-loader
        v-if="fetchFinished != true"
        class="mx-auto"
        style="min-width: 100%; min-height: 100%"
        type="table:table-heading, table-thead, table-tbody"
      ></v-skeleton-loader>
      <div v-else>
        <Toolbar show-search @search="onSearch">
          <ToolbarAction
            icon="mdi-plus"
            :title="$t('button.newDocument')"
            @click="navigateToUpload"
          />
          <ToolbarAction
            icon="mdi-delete"
            :title="$t('button.delete')"
            :disabled="!selected || !selected.canEdit"
            @click="showDeletionDialog"
          />
        </Toolbar>

        <v-data-table
          :items="documents"
          :headers="headers"
          :search="search"
          item-key="id"
          show-select
          single-select
          @item-selected="onSelect"
        >
        </v-data-table>
      </div>

      <ConfirmationDialog
        :show-dialog.sync="showConfirmation"
        :text="$t('message.confirm.deleteDocument')"
        :title="$t('header.delete')"
        :accept-button-text="$t('button.yes')"
        :cancel-button-text="$t('button.no')"
        @onAccept="deleteDocument"
      ></ConfirmationDialog>
      <FileDialog
        :show-dialog.sync="showUpload"
        @acceptUpload="uploadFile"
      ></FileDialog>
      <DocumentViewerDialog
        :show-dialog.sync="showDocumentDialog"
        :file-type="selectedFileType"
        :file-url="selectedFileUrl"
        :file-title="selected ? selected.title : ''"
      ></DocumentViewerDialog>
    </template>
    <template v-slot:content-side :v-if="showSideContent">
      <div class="fill-height">
        <div style="height: 60%" class="pa-1">
          <v-card
            v-if="isLoadingDocument"
            class="fill-height d-flex flex-column"
          >
            {{ $t('message.loadingDocument') }}
          </v-card>
          <v-card v-else class="fill-height d-flex flex-column">
            <v-card-title class="py-2">
              {{ $t('model.document') }}
              <v-spacer></v-spacer>
              <v-btn
                icon
                :title="$t('button.download')"
                @click="downloadDocument"
              >
                <v-icon> mdi-download </v-icon>
              </v-btn>
              <v-btn
                icon
                :title="$t('button.replace')"
                :disabled="!selected.canEdit"
                @click="showFileDialog"
              >
                <v-icon> mdi-upload </v-icon>
              </v-btn>
              <v-btn
                icon
                :title="$t('button.show')"
                @click="showDocumentViewerDialog"
              >
                <v-icon> mdi-file-eye </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="fill-height px-1 pb-2" style="overflow: auto">
              <document-viewer
                :url="selectedFileUrl"
                :mime-type="selectedFileType"
              >
              </document-viewer>
            </v-card-text>
          </v-card>
        </div>

        <div style="height: 40%" class="pa-1">
          <v-card
            v-if="isLoadingProperties"
            class="fill-height d-flex flex-column"
          >
            {{ $t('message.loadingProperties') }}
          </v-card>
          <v-card v-else class="fill-height d-flex flex-column">
            <v-card-title class="py-2">
              Eigenschaften
              <v-spacer></v-spacer>
              <v-btn
                icon
                :title="$t('button.editProperties')"
                :disabled="!selected.canEdit"
                @click="navigateToDetails"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="fill-height" style="overflow-y: auto">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <simple-table-row
                      :name="$t('model.title')"
                      :value="selected.title"
                    ></simple-table-row>
                    <simple-table-row
                      :name="$t('model.docType')"
                      :value="selected.docTypeName"
                    ></simple-table-row>
                    <simple-table-row
                      v-for="(item, index) in selected.details
                        .individualProperties"
                      :key="index"
                      :name="item[localizedName]"
                      :value="item.value"
                    ></simple-table-row>
                    <simple-table-row
                      :name="$t('model.lastModified')"
                      :value="selected.lastModified"
                    ></simple-table-row>
                    <simple-table-row
                      :name="$t('model.lastModifiedBy')"
                      :value="selected.lastModifiedBy"
                    ></simple-table-row>
                    <simple-table-row
                      :name="$t('model.created')"
                      :value="selected.created"
                    ></simple-table-row>
                    <simple-table-row
                      :name="$t('model.createdBy')"
                      :value="selected.createdBy"
                    ></simple-table-row>
                    <simple-table-row
                      :name="$t('model.fileName')"
                      :value="selected.fileName"
                    ></simple-table-row>
                    <simple-table-row
                      :name="$t('model.fileSize')"
                      :value="selected.fileSize"
                    ></simple-table-row>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </template>
  </PageContent>
</template>

<script>
import DocumentApi from '@/api/document.js';
import Notification from '@/service/notification.js';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import FileDialog from '@/components/dialogs/FileDialog.vue';
import DocumentViewerDialog from '@/components/dialogs/DocumentViewerDialog.vue';
import S3Api from '@/api/s3.js';
import Toolbar from '@/components/bars/Toolbar';
import ToolbarAction from '@/components/bars/ToolbarAction';
import DocumentViewer from '@/components/viewer/DocumentViewer.vue';
import PermissionApi from '@/api/permission.js';
import SimpleTableRow from '@/components/table/SimpleTableRow.vue';

export default {
  name: 'DocumentList',
  components: {
    Toolbar,
    ToolbarAction,
    ConfirmationDialog,
    FileDialog,
    DocumentViewer,
    SimpleTableRow,
    DocumentViewerDialog
  },
  data() {
    return {
      docTypes: [],
      documents: [],
      headers: [
        {
          text: this.$t('model.title'),
          value: 'title'
        },
        {
          text: this.$t('model.docType'),
          value: 'docTypeName'
        },
        {
          text: this.$t('model.fileName'),
          value: 'fileName'
        },
        {
          text: this.$t('model.lastModified'),
          value: 'lastModified'
        }
      ],
      currDocProps: {
        title: 'titel',
        fileName: 'fileSize',
        docType: 'Rechnung',
        customerId: '4711',
        address: 'Musterstraße 11'
      },
      selected: null,
      search: '',
      showConfirmation: false,
      showDocumentDialog: false,
      fetchFinished: false,
      showUpload: false,
      isLoadingProperties: false,
      isLoadingDocument: false
    };
  },
  computed: {
    showSideContent: function() {
      return Boolean(this.selected);
    },
    localizedName: function() {
      let lang = this.$i18n.locale;

      if (lang !== 'de') {
        return 'nameEnglish';
      }

      return 'name';
    },
    selectedFileUrl: function() {
      return this.selected &&
        this.selected.currentFile &&
        this.selected.currentFile.url
        ? this.selected.currentFile.url
        : '';
    },
    selectedFileType: function() {
      return this.selected ? this.selected.fileType : '';
    }
  },
  mounted() {
    this.fetchDocTypes();
  },
  methods: {
    showFileDialog() {
      this.showUpload = true;
    },
    showDocumentViewerDialog() {
      this.showDocumentDialog = true;
    },
    onSelect(event, index) {
      console.log('event: ', event);
      console.log('index: ', index);
      if (event.value == false) {
        this.selected = null;
        return;
      }

      this.selected = event.item;
      this.loadDocument(event.item.id);
      this.loadProperties(event.item.id);
    },
    loadProperties(id) {
      this.isLoadingProperties = true;
      DocumentApi.getDocument(id)
        .then(r => {
          console.debug('file response: ', r);
          if (r && this.selected) {
            let details = {
              individualProperties: [],
              standardProperties: r.standardProperties
            };

            if (r.individualProperties) {
              let docType = this.docTypes[r.docTypeId];
              console.debug('doctype: ', docType);

              for (let [key, value] of Object.entries(r.individualProperties)) {
                docType.properties.forEach(p => {
                  if (key === p.property.id) {
                    details.individualProperties.push({
                      name: p.property.name,
                      nameEnglish: p.property.nameEnglish,
                      value: value
                    });
                  }
                });
              }
            }
            this.$set(this.selected, 'details', details);

            this.isLoadingProperties = false;
          }
        })
        .catch(err => {
          console.error('kaputt: ', err);
          Notification.error(this.$t('message.error.showProperties'));
        });
    },
    loadDocument(id) {
      this.isLoadingDocument = true;
      DocumentApi.getFileDownloadLink(id, true)
        .then(r => {
          console.debug('file response: ', r);
          if (r && r.url && this.selected) {
            this.$set(this.selected, 'currentFile', r);

            this.isLoadingDocument = false;
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.showDocument'));
        });
    },
    onSearch(searchText) {
      this.search = searchText;
    },
    showDeletionDialog() {
      this.showConfirmation = true;
    },
    navigateToDetails() {
      if (!this.selected || !this.selected.id) return;
      this.$router.push('/documents/details/' + this.selected.id);
    },
    navigateToNewVersion() {
      if (!this.selected || !this.selected.id) return;
      this.$router.push('/documents/file/' + this.selected.id);
    },
    uploadFile(file) {
      if (!file || !file.name || !this.selected || !this.selected.id) {
        return;
      }

      let uploadRequest = {
        contentType: file.type,
        fileName: file.name,
        fileSizeInByte: file.size
      };

      Notification.inProgress(true);
      DocumentApi.updateFile(this.selected.id, uploadRequest, true)
        .then(r => {
          let config = {
            headers: {
              'Content-Disposition': r.contentDisposition,
              'Content-Type': file.type
            }
          };

          S3Api.putObject(r.url, file, config)
            .then(r => {
              console.info('krass, Datei in S3: ', r);

              let newDoc = this.selected.document;

              newDoc.standardProperties.file.fileName = file.name;
              newDoc.standardProperties.file.fileSizeInByte = file.size;
              newDoc.standardProperties.file.contentType = file.type;

              DocumentApi.putDocument(this.selected.id, newDoc, true)
                .then(d => {
                  let i = this.documents.indexOf(this.selected);
                  this.documents[i].fileName =
                    d.standardProperties.file.fileName;
                  this.documents[i].contentType =
                    d.standardProperties.file.contentType;
                  this.documents[
                    i
                  ].fileSizeInByte = this.$options.filters.formatFileSize(
                    d.standardProperties.file.fileSizeInByte
                  );
                  this.documents[
                    i
                  ].lastModified = this.$options.filters.localeDateTime(
                    d.standardProperties.lastModified
                  );
                  this.documents[i].lastModifiedBy =
                    d.standardProperties.lastModifiedBy;
                  this.documents[i].document = d;

                  Notification.success(this.$t('message.success.replaceFile'));

                  this.loadDocument(this.selected.id);
                })
                .catch(err => {
                  console.log('Cannot replace document', err);
                  Notification.error(this.$t('message.error.replaceFile'));
                })
                .finally(() => Notification.inProgress(false));
            })
            .catch(err => {
              console.log('Cannot replace document', err);
              Notification.error(this.$t('message.error.replaceFile'));
              Notification.inProgress(false);
            });
        })
        .catch(err => {
          console.log('Cannot replace document', err);
          Notification.error(this.$t('message.error.replaceFile'));
          Notification.inProgress(false);
        });
    },
    fetchDocTypes() {
      PermissionApi.listReadDocTypesForIdentity()
        .then(r => {
          if (r.items && r.items.length > 0) {
            this.docTypes = r.map;
          }
          this.fetchDocuments();
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocTypes'));
        });
    },
    fetchDocuments() {
      DocumentApi.fetchDocuments()
        .then(r => {
          if (r.items && r.items.length > 0) {
            this.documents = [];
            r.items.forEach(doc => {
              this.documents.push(this.getFlatDocument(doc));
            });
          }
          this.fetchFinished = true;
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchDocuments'));
        });
    },
    getFlatDocument(doc) {
      let flatDoc = {
        document: doc,
        id: doc.id,
        title: doc.standardProperties.title,
        fileName: doc.standardProperties.file.fileName,
        fileType: doc.standardProperties.file.contentType,
        fileSize: this.$options.filters.formatFileSize(
          doc.standardProperties.file.fileSizeInByte
        ),
        lastModifiedBy: doc.standardProperties.lastModifiedBy,
        lastModified: this.$options.filters.localeDateTime(
          doc.standardProperties.lastModified
        ),
        createdBy: doc.standardProperties.createdBy,
        created: this.$options.filters.localeDateTime(
          doc.standardProperties.created
        ),
        docTypeName: this.docTypes[doc.docTypeId][this.localizedName],
        canEdit: doc.canEdit
      };
      return flatDoc;
    },
    downloadDocument() {
      if (!this.selected || !this.selected.id) return;

      DocumentApi.getFileDownloadLink(this.selected.id)
        .then(r => {
          this.downloadFile(r.url);
        })
        .catch(err => {
          Notification.error(this.$t('message.error.downloadDocument'));
        });
    },
    downloadFile(url) {
      let link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    },
    deleteDocument() {
      if (!this.selected || !this.selected.id) return;

      DocumentApi.deleteDocument(this.selected.id)
        .then(() => {
          Notification.success(this.$t('message.success.deleteDocument'));
          this.removeSelectedDocumentFromList();
        })
        .catch(err => {
          Notification.error(this.$t('message.error.deleteDocument'));
        });
    },
    removeSelectedDocumentFromList() {
      if (!this.selected || !this.documents) return;

      const id = this.selected.id;

      let newDocuments = this.documents.filter(function(obj) {
        return obj.id !== id;
      });

      this.documents = newDocuments;
      this.selected = null;
    },
    navigateToUpload() {
      this.$router.push('documents/upload');
    }
  }
};
</script>
