<template>
  <tr>
    <td>
      <strong>{{ name }}</strong>
    </td>
    <td>{{ value }}</td>
  </tr>
</template>

<script>
export default {
  name: 'SimpleTableRow',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
};
</script>
