import Vue from 'vue';
import i18n from '@/i18n/i18n.js';

Vue.filter('localeDate', function(date) {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return null;
});

Vue.filter('localeDateTime', function(date) {
  if (date) {
    return new Date(date).toLocaleString();
  }
  return null;
});

Vue.filter('propertyType', function(value) {
  switch (value) {
    case 'string':
      return i18n.t('model.docPropType_string');
    case 'decimal':
      return i18n.t('model.docPropType_decimal');
    case 'numeric':
      return i18n.t('model.docPropType_numeric');
    case 'date':
      return i18n.t('model.docPropType_date');
    case 'boolean':
      return i18n.t('model.docPropType_boolean');
  }
});

Vue.filter('roleName', function(value) {
  switch (value) {
    case 'CloudAdmin':
      return i18n.t('model.roles.cloudAdmin');
    case 'CloudSupport':
      return i18n.t('model.roles.cloudSupport');
    case 'CloudSales':
      return i18n.t('model.roles.cloudSales');
    case 'TenantAdmin':
      return i18n.t('model.roles.tenantAdmin');
    case 'TenantUser':
      return i18n.t('model.roles.tenantUser');
    case 'TenantExternal':
      return i18n.t('model.roles.tenantExternal');
    default:
      return value;
  }
});

Vue.filter('propertyTypeIcon', function(value) {
  switch (value) {
    case 'string':
      return 'mdi-alphabetical';
    case 'decimal':
      return 'mdi-decimal';
    case 'numeric':
      return 'mdi-numeric';
    case 'date':
      return 'mdi-calendar-month';
    case 'boolean':
      return 'mdi-order-bool-ascending';
  }
});

Vue.filter('formatFileSize', function(value) {
  if (!value) return '0 B';

  let number = Number(value);
  let extension = ' B';

  if (number > 1024 * 1024 * 1024) {
    number = Math.round((number / (1024 * 1024 * 1024)) * 10) / 10;
    extension = ' GB';
  } else if (number > 1024 * 1024) {
    number = Math.round((number / (1024 * 1024)) * 10) / 10;
    extension = ' MB';
  } else if (number > 1024) {
    number = Math.round((number / 1024) * 10) / 10;
    extension = ' KB';
  }
  return number.toLocaleString() + extension;
});
