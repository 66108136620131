<template>
  <v-icon
    class="clickable-icon"
    @mouseover="isHovered = true"
    @mouseout="isHovered = false"
    @click="emitInputEvent"
    :value="value"
  >
    {{ iconName }}
  </v-icon>
</template>

<script>
export default {
  name: 'Pin',
  computed: {
    iconName: function() {
      if (this.value) {
        if (this.isHovered) {
          return 'mdi-pin-off';
        } else {
          return 'mdi-pin';
        }
      } else {
        if (this.isHovered) {
          return 'mdi-pin';
        } else {
          return 'mdi-pin-outline';
        }
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHovered: false
    };
  },
  methods: {
    emitInputEvent(event) {
      console.log('pin clicked: ', event);
      this.$emit('input', !this.value);
    }
  }
};
</script>

<style scoped>
.clickable-icon {
  cursor: pointer;
}
</style>
