<template>
  <v-navigation-drawer
    v-if="loggedIn"
    :value="showDrawer"
    app
    clipped
    width="320px"
    @input="updateValue"
  >
    <v-toolbar v-if="$vuetify.breakpoint.mobile" elevation="0">
      <v-app-bar-nav-icon @click="updateValue(false)"></v-app-bar-nav-icon>
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        src="@/assets/rc_logo.png"
        transition="scale-transition"
        width="40"
        height="40"
      />
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>

    <!-- Logged In - No Tenant selected -->
    <v-list v-if="loggedIn && !tenantSelected" id="side-bar-public">
      <ListItem
        :title="$t('navigation.selectTenant')"
        to="/tenant/selection"
        icon="mdi-domain"
      ></ListItem>
    </v-list>

    <!-- Tenant Users -->
    <v-list
      v-if="loggedIn && tenantSelected && !isDevOpsUser"
      id="side-bar-member"
    >
      <ListItem
        :title="$t('navigation.home')"
        to="/home"
        icon="mdi-home"
      ></ListItem>
      <ListItem
        :title="$t('navigation.documents')"
        to="/documents"
        icon="mdi-folder"
      ></ListItem>
      <v-list-group
        v-if="currentTenant.role === 'TenantAdmin'"
        v-model="expandSettings"
        no-action
        prepend-icon="mdi-cog"
        color="gray"
      >
        <template v-slot:activator>
          <v-list-item-title>
            {{ $t('navigation.settings') }}
          </v-list-item-title>
        </template>

        <v-row class="d-flex align-center" style="color:#dc291e">
          <v-col>
            <v-divider></v-divider>
          </v-col>
          <v-col>
            <span>{{ $t('navigation.documents') }}</span>
          </v-col>
          <v-col><v-divider></v-divider></v-col>
        </v-row>

        <ListItem
          :title="$t('navigation.docProperties')"
          to="/settings/properties"
          icon="mdi-format-list-bulleted-type"
        ></ListItem>
        <ListItem
          :title="$t('navigation.docTypes')"
          to="/settings/doctypes"
          icon="mdi-file-document"
        ></ListItem>
        <v-row class="d-flex align-center" style="color:#dc291e">
          <v-col>
            <v-divider></v-divider>
          </v-col>
          <v-col>
            <span>{{ $t('navigation.permissions') }}</span>
          </v-col>
          <v-col><v-divider></v-divider></v-col>
        </v-row>
        <ListItem
          :title="$t('navigation.user')"
          to="/settings/users"
          icon="mdi-account-multiple"
        ></ListItem>
        <ListItem
          :title="$t('navigation.groups')"
          to="/settings/groups"
          icon="mdi-account-group"
        ></ListItem>
        <ListItem
          :title="$t('navigation.attributes')"
          to="/settings/attributes"
          icon="mdi-account-details"
        ></ListItem>
      </v-list-group>
    </v-list>

    <!-- Cloud Users -->
    <v-list
      v-if="loggedIn && tenantSelected && isDevOpsUser"
      id="side-bar-member"
    >
      <ListItem
        :title="$t('navigation.home')"
        to="/home"
        icon="mdi-home"
      ></ListItem>
      <ListItem
        v-if="currentTenant.role === 'CloudAdmin'"
        :title="$t('navigation.user')"
        to="/settings/users"
        icon="mdi-account-multiple"
      ></ListItem>
      <ListItem
        :title="$t('navigation.tenantOverview')"
        to="/tenants"
        icon="mdi-domain"
      ></ListItem>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { store, mutations } from '@/store/store.js';
import ListItem from '@/components/lists/ListItem.vue';

export default {
  name: 'SideBar',
  components: {
    ListItem
  },
  data: function() {
    return {
      expandSettings: false
    };
  },
  computed: {
    loggedIn: function() {
      return Boolean(store.getUser());
    },
    currentTenant: function() {
      return store.getTenant();
    },
    tenantSelected: function() {
      return Boolean(store.getTenant());
    },
    isDevOpsUser: function() {
      return Boolean(store.getIsDevOps());
    },
    showDrawer: function() {
      return store.getDrawerState();
    },
    title: function() {
      return store.getTitle();
    }
  },
  mounted() {
    if (location.pathname.startsWith('/settings')) {
      this.expandSettings = true;
    }
  },
  methods: {
    updateValue(event) {
      if (store.getDrawerState() !== event) mutations.setDrawerState(event);
    }
  }
};
</script>
