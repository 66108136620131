<template>
  <v-btn
    :disabled="disabled"
    :title="title"
    icon
    :type="type"
    @click="$emit('click', $event)"
  >
    <v-icon>
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ToolbarAction',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
