<template>
  <v-list>
    <ListItem
      v-for="(item, index) in items"
      :key="index"
      :to="item.to"
      :title="item.title"
      :icon="item.icon"
    ></ListItem>
  </v-list>
</template>

<script>
import ListItem from '@/components/lists/ListItem.vue';

export default {
  name: 'IconList',
  components: {
    ListItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>
