<template>
  <div :style="`height: ${height}; width: ${width};`">
    <object
      v-if="isPdf || isText"
      :data="url"
      :type="mimeType"
      height="100%"
      width="100%"
    >
      {{ $t('message.error.displayDocument') }}
    </object>
    <v-img v-else-if="isImage" :src="url"></v-img>
    <div v-else>
      {{ $t('message.error.displayDocument') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentViewer',
  props: {
    url: {
      type: String,
      required: true
    },
    mimeType: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '100%'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  computed: {
    isPdf: function() {
      return this.mimeType === 'application/pdf';
    },
    isText: function() {
      return this.mimeType === 'text/plain';
    },
    isImage: function() {
      return this.mimeType.startsWith('image');
    }
  }
};
</script>
