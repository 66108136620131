import { client, clientWithoutSpinner } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  uploadDocument(request) {
    return clientWithoutSpinner
      .post('/document', request, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  deleteDocument(id) {
    return client
      .delete(`/document/${id}`, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  fetchDocuments() {
    return client
      .get('/document', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getLatestDocuments() {
    return client
      .get('/document/latest', {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  getDocument(id) {
    return client
      .get(`/document/${id}`, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  putDocument(id, body, withoutSpinner) {
    if (withoutSpinner) {
      return clientWithoutSpinner
        .put(`/document/${id}`, body, {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    } else {
      return client
        .put(`/document/${id}`, body, {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    }
  },
  updateFile(id, body, withoutSpinner) {
    if (withoutSpinner) {
      return clientWithoutSpinner
        .put(`/document/${id}/file`, body, {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    } else {
      return client
        .put(`/document/${id}/file`, body, {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    }
  },
  getFileDownloadLink(id, isEmbedded) {
    let link = `/document/${id}/file`;
    if (isEmbedded === true) {
      link += '?embedded=true';
    }

    return client
      .get(link, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
