<template>
  <form @submit.prevent="login">
    <h2>
      {{ $t('header.login') }}
    </h2>
    <v-text-field v-model="email" :label="$t('model.email')"></v-text-field>
    <v-text-field
      v-model="password"
      :label="$t('model.password')"
      type="password"
      autocomplete="password"
    ></v-text-field>

    <router-link to="/resetPassword">
      {{ $t('navigation.forgotPassword') }}
    </router-link>

    <v-btn type="submit" class="float-right">
      <v-icon left> mdi-login </v-icon>
      {{ $t('button.login') }}
    </v-btn>
  </form>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    login() {
      if (this.email && this.password) {
        this.$emit('login', this.email, this.password);
      }
    }
  }
};
</script>
