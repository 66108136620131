<template>
  <v-btn
    :color="color"
    :icon="$vuetify.breakpoint.name === 'xs'"
    :disabled="disabled"
    :type="type"
    @click="$emit('click', $event)"
  >
    <v-icon v-if="iconLeft" :left="$vuetify.breakpoint.name !== 'xs'">
      {{ icon }}
    </v-icon>
    <span v-if="$vuetify.breakpoint.name !== 'xs'">
      {{ text }}
    </span>
    <v-icon v-if="iconRight" :right="$vuetify.breakpoint.name !== 'xs'">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ResponsiveButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
button {
  display: inline-block;
}
</style>
