import colors from 'vuetify/lib/util/colors';

export default {
  light: {
    primary: '#dc291e',
    secondary: '#d5d2cf',
    tertiary: '#787678',
    quaternary: '#2e2d32',
    danger: colors.red.lighten1,
    success: colors.green.lighten3,
    info: colors.blue.lighten3
  },
  dark: {
    primary: colors.red.darken2,
    secondary: colors.grey.lighten2,
    tertiary: colors.pink.base,
    danger: colors.red.lighten1,
    success: colors.green.lighten3,
    info: colors.blue.lighten3
  }
};
