import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store/store.js';
import AuthService from '@/service/auth';

//DevOps
import TenantList from '@/views/devops/TenantList.vue';
import TenantCreation from '@/views/devops/TenantCreation.vue';

//Tenant
import DocumentList from '@/views/tenant/document/DocumentList.vue';
import DocumentDetails from '@/views/tenant/document/DocumentDetails.vue';
import PropertyList from '@/views/tenant/property/PropertyList.vue';
import PropertyDetails from '@/views/tenant/property/PropertyDetails.vue';
import DocTypeList from '@/views/tenant/docType/DocTypeList.vue';
import DocTypeDetails from '@/views/tenant/docType/DocTypeDetails.vue';
import AttributeList from '@/views/tenant/attribute/AttributeList.vue';
import AttributeDetails from '@/views/tenant/attribute/AttributeDetails.vue';
import GroupList from '@/views/tenant/group/GroupList.vue';
import GroupDetails from '@/views/tenant/group/GroupDetails.vue';
import UserRating from '@/views/tenant/rating/UserRating.vue';

//Shared
import Profile from '@/views/shared/profile/Profile.vue';
import Verification from '@/views/shared/profile/Verification.vue';
import Password from '@/views/shared/profile/Password.vue';
import Logout from '@/views/shared/profile/Logout.vue';
import Home from '@/views/shared/home/Home.vue';
import UserList from '@/views/shared/user/UserList.vue';
import UserDetails from '@/views/shared/user/UserDetails.vue';
import TenantSelection from '@/views/shared/tenant/TenantSelection.vue';

//Public
import Welcome from '@/views/public/Welcome.vue';
import Login from '@/views/public/Login.vue';
import ResetPassword from '@/views/public/ResetPassword.vue';

Vue.use(VueRouter);

async function updateCurrentUser() {
  await AuthService.updateCurrentUser();
}

const routes = [
  {
    path: '/',
    name: 'Landing',
    beforeEnter(_to, _from, next) {
      if (store.getUser()) {
        next('/home');
      } else {
        next('/welcome');
      }
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/settings/users',
    name: 'UserList',
    component: UserList,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin', 'CloudAdmin']
    }
  },
  {
    path: '/tenants',
    name: 'TenantList',
    component: TenantList,
    meta: {
      requiresAuth: true,
      allowedRoles: ['CloudAdmin', 'CloudSupport', 'CloudSales']
    }
  },
  {
    path: '/tenants/new',
    name: 'TenantCreation',
    component: TenantCreation,
    meta: {
      requiresAuth: true,
      allowedRoles: ['CloudAdmin']
    }
  },
  {
    path: '/tenant/selection',
    name: 'TenantSelection',
    component: TenantSelection,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/users/:id',
    name: 'UserDetails',
    component: UserDetails,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin', 'CloudAdmin']
    },
    props: true
  },
  {
    path: '/documents',
    name: 'DocumentList',
    component: DocumentList,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin', 'TenantUser', 'TenantExternal']
    }
  },
  {
    path: '/documents/details/:id',
    name: 'DocumentDetails',
    component: DocumentDetails,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin', 'TenantUser', 'TenantExternal']
    },
    props: true
  },
  {
    path: '/documents/upload',
    name: 'DocumentUpload',
    component: DocumentDetails,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin', 'TenantUser']
    }
  },
  {
    path: '/rating',
    name: 'UserRating',
    component: UserRating,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin', 'TenantUser']
    }
  },
  {
    path: '/settings/properties',
    name: 'PropertyList',
    component: PropertyList,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    }
  },
  {
    path: '/settings/properties/:id',
    name: 'PropertyDetails',
    component: PropertyDetails,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    },
    props: true
  },
  {
    path: '/settings/doctypes',
    name: 'DocTypeList',
    component: DocTypeList,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    }
  },
  {
    path: '/settings/doctypes/:id',
    name: 'DocTypeDetails',
    component: DocTypeDetails,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    },
    props: true
  },
  {
    path: '/settings/attributes',
    name: 'AttributeList',
    component: AttributeList,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    }
  },
  {
    path: '/settings/attributes/new',
    name: 'AttributeDetails',
    component: AttributeDetails,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    }
  },
  {
    path: '/settings/groups',
    name: 'GroupList',
    component: GroupList,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    }
  },
  {
    path: '/settings/groups/:id',
    name: 'GroupDetails',
    component: GroupDetails,
    meta: {
      requiresAuth: true,
      allowedRoles: ['TenantAdmin']
    },
    props: true
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      allowedRoles: [
        'CloudAdmin',
        'CloudSupport',
        'CloudSales',
        'TenantAdmin',
        'TenantUser',
        'TenantExternal'
      ]
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/verification',
    name: 'Verification',
    component: Verification,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/password',
    name: 'Password',
    component: Password,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter(_to, _from, next) {
      if (store.getUser()) {
        next('/home');
      } else {
        next();
      }
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  await updateCurrentUser();
  return next();
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getUser()) {
    console.debug('authorization required');
    return next({
      path: '/',
      query: {
        redirect: to.fullPath
      }
    });
  }
  if (to.meta.allowedRoles) {
    console.log('to: ', to);
    let tenant = store.getTenant();

    if (!tenant) {
      console.debug('tenant required');
      return next({
        path: '/tenant/selection',
        query: {
          redirect: to.fullPath
        }
      });
    }

    var hasRole = false;
    for (let i = 0; i < to.meta.allowedRoles.length; i++) {
      if (to.meta.allowedRoles[i] === tenant.role) {
        hasRole = true;
        break;
      }
    }

    if (!hasRole) {
      console.debug('another role required');
      return next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      });
    }
  }
  return next();
});

export default router;
