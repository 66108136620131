import { client, clientWithoutSpinner } from './axios-client.js';
import { store } from '@/store/store.js';

export default {
  listAttributes(withoutSpinner) {
    if (withoutSpinner) {
      return clientWithoutSpinner
        .get('/document/attribute', {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    } else {
      return client
        .get('/document/attribute', {
          headers: { 'x-rc-tenant-id': store.getTenant().id }
        })
        .then(response => response.data);
    }
  },
  createAttribute(atrr) {
    return client
      .post('/document/attribute', atrr, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  },
  deleteAttribute(id) {
    return client
      .delete('/document/attribute/' + id, {
        headers: { 'x-rc-tenant-id': store.getTenant().id }
      })
      .then(response => response.data);
  }
};
