<template>
  <PageContent>
    <template v-slot:header>
      <h2>{{ $t('header.user') }}</h2>
    </template>
    <template v-slot:content>
      <v-card class="mx-2">
        <v-card-title>Allgemein</v-card-title>
        <v-card-text>
          <v-form v-if="identity" class="px-2">
            <v-text-field
              v-model="identity.email"
              :label="$t('model.email') + '*'"
              :disabled="Boolean(id) && id !== 'new'"
            ></v-text-field>
            <v-text-field
              v-model="identity.name"
              :label="$t('model.name')"
              :disabled="Boolean(id) && id !== 'new'"
            ></v-text-field>

            <v-select
              v-model="identity.role"
              :items="roles"
              :label="$t('model.role') + '*'"
              item-text="name"
              item-value="key"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="mb-2">
            <ResponsiveButton
              color="primary"
              class="ml-2 float-right"
              :text="$t('button.save')"
              icon="mdi-content-save"
              icon-right
              :disabled="!canSaveIdentity"
              @click="saveIdentity"
            >
            </ResponsiveButton>
          </div>
        </v-card-actions>
      </v-card>
      <v-card v-if="!isDevOps && !isNew" class="mx-2 mt-3">
        <v-card-title> Gruppen und Attribute</v-card-title>
        <v-card-text>
          <div class="pl-2">
            <h4>
              {{ $t('header.groups') }}
            </h4>
            <div
              v-if="
                user && user.groups && Object.entries(user.groups).length > 0
              "
            >
              <v-chip
                v-for="[key, val] in Object.entries(user.groups)"
                :key="key"
                class="ma-1"
                label
                :href="'/settings/groups/' + key"
              >
                {{ val[attributeLabel] }}
                <v-icon right>
                  mdi-pencil
                </v-icon>
              </v-chip>
            </div>
            <div v-else>
              {{ $t('message.info.noGroupsAssigned') }}
            </div>

            <h4 class="mt-3">
              {{ $t('header.attributes') }}
            </h4>
            <v-row>
              <v-col cols="5">
                <v-select
                  v-model="attributeKey"
                  :items="availableAttributes"
                  :label="$t('model.selectAttribute')"
                  :loading="loadingAttributes"
                  :disabled="loadingAttributes"
                  :item-text="attributeLabel"
                  item-value="id"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="attributeValue"
                  :label="$t('model.value')"
                  @keyup.enter.native="saveAttribute"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <ResponsiveButton
                  icon="mdi-plus"
                  icon-right
                  :text="$t('button.add')"
                  class="align-self-baseline"
                  :disabled="!canSaveAttribute"
                  @click="saveAttribute"
                >
                </ResponsiveButton>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item
                v-for="(attribute, index) in selectedAttributes"
                :key="index"
              >
                <v-list-item-content>
                  <strong>{{ attribute.key[attributeLabel] }}:</strong>
                  {{ attribute.value }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    :label="$t('button.delete')"
                    @click="removeAttribute(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:actions>
      <div class="pt-2">
        <ResponsiveButton
          icon="mdi-close-circle"
          icon-left
          :text="$t('button.cancel')"
          @click="navigateToList"
        >
        </ResponsiveButton>
      </div>
    </template>
  </PageContent>
</template>

<script>
import IdentityApi from '@/api/identity.js';
import AttributeApi from '@/api/attribute.js';
import Notification from '@/service/notification';
import Utils from '@/service/utils.js';
import { store } from '@/store/store.js';

export default {
  name: 'UserDetails',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loadingAttributes: false,
      availableAttributes: [],
      selectedAttributes: [],
      attributeKey: '',
      attributeValue: '',
      roles: [],
      user: {},
      identity: {}
    };
  },
  computed: {
    isDevOps: function() {
      return store.getTenant().id === 'devops';
    },
    canSaveIdentity: function() {
      return (
        Boolean(this.identity) &&
        Boolean(this.identity.email) &&
        Boolean(this.identity.role)
      );
    },
    canSaveAttribute: function() {
      return Boolean(this.attributeKey) && Boolean(this.attributeValue);
    },
    isNew: function() {
      return this.id === 'new';
    },
    attributeLabel: function() {
      let lang = this.$i18n.locale;

      if (lang !== 'de') {
        return 'nameEnglish';
      }

      return 'name';
    }
  },
  mounted() {
    this.fetchRoles();
    this.fetchUserGroups();
  },
  methods: {
    saveIdentity() {
      if (!this.canSaveIdentity) return;

      if (this.isNew) {
        IdentityApi.addIdentity(this.identity)
          .then(r => {
            Notification.success(this.$t('message.success.createUser'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.createUser'));
          });
      } else {
        IdentityApi.updateIdentity(this.identity)
          .then(r => {
            Notification.success(this.$t('message.success.editUser'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.saveUser'));
          });
      }
    },
    fetchUserGroups() {
      if (this.id === 'new' || this.isDevOps) {
        this.fetchAttributes();
        return;
      }

      IdentityApi.getIdentity(this.id)
        .then(r => {
          if (r) {
            this.user = r;
            this.fetchAttributes();
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchUser'));
        });
    },
    fetchRoles() {
      IdentityApi.listRoles()
        .then(r => {
          if (r && r.items) {
            r.items.forEach(item => {
              let role = {
                key: item,
                name: this.$options.filters.roleName(item)
              };
              this.roles.push(role);
            });

            if (this.isNew === false) {
              console.debug('fetch identity');
              IdentityApi.getIdentityDetails(this.id)
                .then(idn => {
                  if (idn) {
                    this.identity = idn;
                  }
                })
                .catch(err => {
                  Notification.error(this.$t('message.error.fetchUser'));
                });
            }
          }
        })
        .catch(err => {
          Notification.error(this.$t('message.error.fetchRoles'));
        });
    },
    navigateToList() {
      this.$router.push('/settings/users');
    },
    fetchAttributes() {
      if (this.isDevOps) {
        return;
      }

      this.loadingAttributes = true;
      AttributeApi.listAttributes(true)
        .then(r => {
          if (r && r.items && r.items.length > 0) {
            this.availableAttributes = r.items;
            this.availableAttributes.sort(
              Utils.sortByPropertyName(this.attributeLabel)
            );

            if (this.user && this.user.attributes) {
              var attrsToRefresh = Object.assign({}, this.user.attributes);
              this.user.attributes = {};

              this.$nextTick(() => {
                for (let [key, value] of Object.entries(attrsToRefresh)) {
                  this.selectAttribute(key, value);
                }
              });
            }
          }
        })
        .catch(err => {
          console.debug(err);
          Notification.error(this.$t('message.error.fetchAttributes'));
        })
        .finally(() => (this.loadingAttributes = false));
    },
    saveAttribute() {
      if (!this.canSaveAttribute) {
        return;
      }

      let attribute = {
        identityId: this.id,
        attributeId: this.attributeKey,
        value: this.attributeValue
      };

      IdentityApi.addAttribute(attribute)
        .then(att => {
          this.selectAttribute(this.attributeKey, this.attributeValue);
          this.attributeKey = '';
          this.attributeValue = '';
        })
        .catch(err => {
          Notification.error(this.$t('message.error.addAttribute'));
        });
    },
    selectAttribute(attributeKey, attributeValue) {
      console.debug('selectAttribute - key: ', attributeKey);
      console.debug('selectAttribute - value: ', attributeValue);

      var index = this.availableAttributes.map(e => e.id).indexOf(attributeKey);

      if (index < 0) return;

      let attr = {
        key: this.availableAttributes[index],
        value: attributeValue
      };

      this.selectedAttributes.push(attr);
      this.availableAttributes.splice(index, 1);

      if (!this.user.attributes) this.user.attributes = {};
      this.user.attributes[attributeKey] = attributeValue;
    },
    removeAttribute(index) {
      var attrToRemove = this.selectedAttributes[index].key;
      console.debug('atrrToRemove: ', attrToRemove);

      IdentityApi.deleteAttribute(this.id, attrToRemove.id)
        .then(() => {
          this.availableAttributes.push(attrToRemove);
          this.availableAttributes.sort(
            Utils.sortByPropertyName(this.attributeLabel)
          );

          this.selectedAttributes.splice(index, 1);

          delete this.user.attributes[attrToRemove.id];
        })
        .catch(err => {
          Notification.error('message.error.removeAttribute');
        });
    },
    save() {
      if (!this.user && !this.user.email) return;

      if (!this.user.id || this.id === 'new') {
        IdentityApi.addIdentity(this.user)
          .then(r => {
            Notification.success(this.$t('message.success.createUser'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.createUser'));
          });
      } else {
        IdentityApi.updateIdentity(this.user)
          .then(r => {
            Notification.success(this.$t('message.success.editUser'));
            this.navigateToList();
          })
          .catch(err => {
            Notification.error(this.$t('message.error.saveUser'));
          });
      }
    }
  }
};
</script>
